import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import * as S from "./styles";

const Breadcrumbs = () => {
  const [crumbs, setCrumbs] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const paths = location.pathname
        .split("/")
        .filter(p => p.length > 0 && p !== "home" && isNaN(parseInt(p)));

      let crumbArray = [];
      let incrementalPath = "";
      paths.forEach(p => {
        incrementalPath += `/${p}`;
        crumbArray = [...crumbArray, { name: p, path: incrementalPath }];
      });

      setCrumbs(crumbArray);
    }
  }, [location]);

  const getUserFriendlyPath = path => {
    switch (path) {
      case "optionals":
        return "adicionais";

      case "model-types":
        return "modelos";

      case "products":
        return "produtos";

      case "carousel-slides":
        return "slides";

      case "users":
        return "usuários";

      case "register":
        return "cadastrar";

      case "edit":
        return "editar";

      case "edit-profile":
        return "editar perfil";

      default:
        return path;
    }
  };

  return (
    <S.BreadcrumbsWrapper className="flexbox v-centered h-padded">
      <span
        className="material-symbols-rounded"
        style={{ marginRight: "0.5rem" }}
      >
        home
      </span>
      <Link
        to={"/home"}
        className={location.pathname === "/home" ? "selected" : null}
      >
        Home
      </Link>
      {crumbs.map((c, i) => {
        return (
          <div key={i} className="flexbox v-centered">
            <span className="material-symbols-rounded">chevron_right</span>
            <Link
              to={c.path}
              className={i === crumbs.length - 1 ? "selected" : null}
            >
              {getUserFriendlyPath(c.name)}
            </Link>
          </div>
        );
      })}
    </S.BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
