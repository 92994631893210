import { useEffect, useState } from "react";
import * as S from "./styles";

import { api } from "../../services/api";

const MediaLibraryCard = ({ data, selectState, selectHandler }) => {
  const [isFolder, setIsFolder] = useState(false);
  const [mediaSrc, setMediaSrc] = useState("");

  useEffect(() => {
    if (data.path) {
      setMediaSrc(`${api.getUri()}/storage/${data.path}`);
      setIsFolder(false);
    } else {
      setIsFolder(true);
    }
  }, [data]);

  const findSelfInSelected = () => {
    return selectState.findIndex(item => item.id === data.id);
  };

  const isSelected = () => {
    const index = findSelfInSelected();
    return index !== -1;
  };

  const toggleSelection = () => {
    const copy = [...selectState];
    const index = findSelfInSelected();
    if (index === -1) {
      copy.push(data);
    } else {
      copy.splice(index, 1);
    }

    selectHandler(copy);
  };

  const renderFolder = () => {
    return (
      <div>
        <p>
          <span className="material-symbols-rounded">folder</span>
        </p>
        <p>{data.name}</p>
      </div>
    );
  };

  const renderMedia = () => {
    return data.media_type === "Image" ? (
      <img src={mediaSrc} alt="" />
    ) : (
      <video src={mediaSrc}></video>
    );
  };

  return (
    <S.ImgPreviewCardWrapper
      className={isSelected() && "selected"}
      onClick={toggleSelection}
    >
      {isFolder ? renderFolder() : renderMedia()}

      {((isFolder &&
        data.subfolders?.length === 0 &&
        data.media?.length === 0) ||
        (!isFolder && data.products?.length === 0)) && (
        <div className="alert">
          <span className="material-symbols-rounded">priority_high</span>
        </div>
      )}
    </S.ImgPreviewCardWrapper>
  );
};

export default MediaLibraryCard;
