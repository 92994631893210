import { styled } from "styled-components";

export const UserFormWrapper = styled.form`
  background: var(--light);
  padding: 1.5rem 1rem;
  border-radius: 10px;

  & > div {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  label.m-sm {
    margin-bottom: 0.25rem;
  }
`;

export const ResetBtnWrapper = styled.div`
  margin-top: 2rem;

  @media only screen and (max-width: 520px) {
    margin-top: 1rem;

    button {
      width: 100%;
    }
  }
`;
