import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --blue: #273782;
        --blue2: #3F66FB;
        --blue3: #DAE9F0;
        --green2: #E4F599;
        --purple: #DCD2ED;
        --purple2: #7700EE;
        --purple3: #1F0638;
        --yellow: #FFC510;
        --yellow2: #FFD85A;
        --orange: #FFBBAC;
        --orange2: #FCBE7C;
        --lead: #545E75;
        --ice: #F4F5F8;

        --green: #3DC13C;
        --red: #F13637;
        --red2: #EDD2D2;

        --dark: #030303;
        --grey1: #3A393D;
        --grey2: #55555F;
        --grey3: #D9D9D9;
        --grey4: #7E7E7E;
        --grey5: #868686;
        --grey6: #D4D3D2;
        --light: #E2E0E5;
        --light2: #FAFBFD;
        --darker: #000000;
        --lighter: #FFFFFF;

        --hPadding: 5rem;
    }

    body {
        margin: 0;
        font-family: "Be Vietnam Pro", sans-serif;

        & *:not(.swal2-icon) {
            box-sizing: border-box;
        }
    }

    h1, h2, h3, h4, h5, h6, p, a, button {
        margin: 0;
        text-decoration: none;
    }

    h1 {
        margin-bottom: 1.5rem;
    }

    a {
        display: block;
    }

    button {
        background: none;
        border: none;
        cursor: pointer;
    }

    label {
        display: block;
        margin-bottom: 0.5rem;

        @media only screen and (max-width: 520px) {
            font-size: 14px;
        }

        @media only screen and (max-width: 300px) {
            font-size: 12px;
        }
    }

    input, textarea, select {
        width: 100%;
        border: 1px solid var(--grey2);
        border-radius: 5px;
        font-size: 16px;
        padding: 0.75rem;
        margin: 0;
        resize: none;
        font-family: "Be Vietnam Pro", sans-serif;

        @media only screen and (max-width: 520px) {
            font-size: 14px;
            padding: 0.5rem;
        }

        @media only screen and (max-width: 300px) {
            font-size: 12px;
        }
    }

    span.eye-btn {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: var(--lead);
    }

    div.requirements {
        margin-bottom: 0.5rem;

        & > p {
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            display: flex;
            align-items: center;
            font-size: 12px;
        
            .material-symbols-rounded {
                font-size: 12px;
                margin-right: 0.25rem;
            }
        
            &.neutral {
                color: var(--purple3);
            }
        
            &.success {
                color: var(--blue2);
            }
        
            &.error {
                color: var(--red);
            }

            @media only screen and (max-width: 300px) {
                font-size: 10px;

                .material-symbols-rounded {
                    font-size: 10px;
                }
            }
        }
    }

    .h-padded {
        padding-left: var(--hPadding);
        padding-right: var(--hPadding);

        @media only screen and (max-width: 1120px) {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media only screen and (max-width: 1000px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media only screen and (max-width: 750px) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .v-padded-md {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .v-padded-lg {
        padding-top: 0.5rem;
        padding-bottom: 3rem;

        @media only screen and (max-width: 750px) {
            padding-bottom: 5rem;
        }
    }

    .flexbox {
        display: flex;
    }

    .flex-col {
        flex-direction: column;
    }

    .v-centered {
        align-items: center;
    }

    .h-centered {
        justify-content: center;
    }

    .h-spaced-between {
        justify-content: space-between;
    }

    .btn {
        width: fit-content;
        border-radius: 5px;
        padding: 0.5rem 2rem;
        color: var(--lighter);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 300ms;
        
        span {
            pointer-events: none;
            
            &.btn-text {
                margin-left: 0.5rem;
                font-size: 18px;

                @media only screen and (max-width: 520px) {
                    font-size: 16px;
                }

                @media only screen and (max-width: 300px) {
                    font-size: 14px;
                }
            }
        }

        input {
            pointer-events: none;
        }

        &.blue {
            background: var(--blue2);

            &:hover {
                background: var(--blue);
            }
        }

        &.lead {
            background: var(--lead);

            &:hover {
                background: var(--grey1);
            }
        }

        &.green {
            background: var(--green);
        }

        &.red {
            background: var(--red);

            &:hover {
                background: var(--orange);
                color: var(--red);
            }
        }

        &.confirm {
            background: var(--purple2);

            &:hover {
                background: var(--purple3);
            }
        }

        &.gold {
            background: var(--lead);

            &:hover {
                color: var(--yellow2);
                background: var(--grey1);
            }

            &.active {
                background: var(--yellow);
                
                &:hover {
                    color: var(--grey1);
                    background: var(--light2);
                }
            }
        }

        &.cancel {
            background: var(--lighter);
            border: 2px solid var(--purple3);
            color: var(--purple3);

            &:hover {
                background: var(--lead);
                border-color: var(--lead);
                color: var(--lighter);
            }
        }

        &:disabled {
            background: var(--grey2);
            pointer-events: none;
        }

        &.lg {
            font-size: 18px;
            padding: 1rem 5rem;
        }

        &.md {
            padding: 0.5rem 0.6rem;
        }

        &.full {
            width: 100%;
        }
    }

    .material-symbols-rounded {
        font-size: 20px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
`;

export default GlobalStyle;
