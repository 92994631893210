import { useEffect, useRef, useState } from "react";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
import * as S from "../styles/signedPages";

import Header from "../components/Header";
import Loading from "../components/Loading";
import MediaLibrary from "../components/MediaLibrary";
import Navbar from "../components/Navbar";
import Breadcrumbs from "../components/Breadcrumbs";

import { useMediaLib } from "../contexts/MediaLibContext";
import { useLoading } from "../contexts/LoadingContext";
import { useAuth } from "../contexts/AuthContext";

import { api } from "../services/api";
import { onRequestError } from "../services/functions";

const SignedLayout = () => {
  const [checkedAuth, setCheckedAuth] = useState(false);

  const didCallApi = useRef(false);

  const { showMediaLib } = useMediaLib();
  const { loading } = useLoading();
  const { user, setUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkedAuth) return;

    const getUser = async () => {
      await api
        .get("/api/user")
        .then(response => setUser(response.data))
        .catch(e => {
          if (e.status === 409) {
            navigate("/confirm-account");
          }
          onRequestError(e.msg, e);
        })
        .finally(() => {
          setCheckedAuth(true);
          didCallApi.current = false;
        });
    };

    if (!user) {
      if (!didCallApi.current) {
        didCallApi.current = true;
        getUser();
      }
    } else {
      setCheckedAuth(true);
    }

    // eslint-disable-next-line
  }, [location]);

  if (!checkedAuth) {
    return <Loading />;
  }

  return user ? (
    <>
      {showMediaLib && <MediaLibrary />}
      {loading && <Loading />}
      <S.PageWrapper>
        <S.ContentWrapper>
          <Breadcrumbs />
          <Outlet />
        </S.ContentWrapper>
      </S.PageWrapper>
      <Header user={user} />
      <Navbar user={user} />
    </>
  ) : (
    <Navigate to={"/"} />
  );
};

export default SignedLayout;
