import React, { useEffect, useState } from "react";
import * as S from "../styles/homePage";
import { useNavigate } from "react-router-dom";

import { api } from "../services/api";
import { onRequestError } from "../services/functions";

import { useMediaLib } from "../contexts/MediaLibContext";
import { useLoading } from "../contexts/LoadingContext";

const StatisticsCard = ({ title, color, urlOrHandler, count, statistics }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (typeof urlOrHandler === "string") {
      navigate(urlOrHandler);
    } else {
      urlOrHandler();
    }
  };

  return (
    <S.HomePageSectionWrapper color={color} onClick={handleClick}>
      <h2>{title}</h2>
      <p>
        <span>Cadastrados: </span>
        <span>{count}</span>
      </p>
      {count > 0 && statistics?.length > 0 && (
        <>
          {statistics.map((stat, i) => {
            if (stat.count > 0) {
              return (
                <p key={i}>
                  <span>{stat.name}: </span>
                  <span>{stat.count}</span>
                </p>
              );
            } else {
              return null;
            }
          })}
        </>
      )}
    </S.HomePageSectionWrapper>
  );
};

const Home = () => {
  const [products, setProducts] = useState([]);
  const [media, setMedia] = useState([]);
  const [carouselSlides, setCarouselSlides] = useState([]);
  const [modelTypes, setModelTypes] = useState([]);
  const [optionals, setOptionals] = useState([]);

  const { showMediaLib, setShowMediaLib } = useMediaLib();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (showMediaLib) return;

    const getApiResources = async () => {
      const apiCalls = [
        api.get("/api/products"),
        api.get("/api/product-media"),
        api.get("/api/carousel-slides"),
        api.get("/api/model-types"),
        api.get("/api/optionals"),
      ];
      await Promise.all(apiCalls)
        .then(responses => {
          setProducts(responses[0].data);
          setMedia(responses[1].data);
          setCarouselSlides(responses[2].data);
          setModelTypes(responses[3].data);
          setOptionals(responses[4].data);
        })
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    };

    setLoading(true);
    getApiResources();
  }, [setLoading, showMediaLib]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Estatísticas</h1>
      <S.HomePageWrapper>
        <StatisticsCard
          title={"Adicionais"}
          color={"var(--blue3)"}
          urlOrHandler={"/optionals"}
          count={optionals.length}
          statistics={[
            {
              name: "Não utilizados",
              count: optionals.filter(opt => opt.products.length === 0).length,
            },
          ]}
        />

        <StatisticsCard
          title={"Modelos"}
          color={"var(--green2)"}
          urlOrHandler={"/model-types"}
          count={modelTypes.length}
          statistics={[
            {
              name: "Não utilizados",
              count: modelTypes.filter(mod => mod.products.length === 0).length,
            },
          ]}
        />

        <StatisticsCard
          title={"Produtos"}
          color={"var(--purple)"}
          urlOrHandler={"/products"}
          count={products.length}
          statistics={[
            {
              name: "Sem modelos",
              count: products.filter(prod => prod.model_types.length === 0)
                .length,
            },
            {
              name: "Sem mídia",
              count: products.filter(prod => prod.media.length === 0).length,
            },
          ]}
        />

        <StatisticsCard
          title={"Slides"}
          color={"var(--red2)"}
          urlOrHandler={"/carousel-slides"}
          count={carouselSlides.length}
          statistics={[
            {
              name: "Sem imagem",
              count: carouselSlides.filter(slide => !slide.image_path).length,
            },
          ]}
        />

        <StatisticsCard
          title={"Mídia"}
          color={"var(--orange2)"}
          urlOrHandler={() => {
            setShowMediaLib(true);
          }}
          count={media.length}
          statistics={[
            {
              name: "Não utilizados",
              count: media.filter(med => med.products.length === 0).length,
            },
          ]}
        />
      </S.HomePageWrapper>
    </div>
  );
};

export default Home;
