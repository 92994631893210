import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CarouselSlideForm from "../../components/CarouselSlideForm";
import { api } from "../../services/api";
import { onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const UpdateCarouselSlides = () => {
  const [carouselSlide, setCarouselSlide] = useState();
  const [products, setProducts] = useState(null);
  const [allSlides, setAllSlides] = useState(null);

  const params = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const didcallAPI = useRef(false);

  useEffect(() => {
    if (!params || didcallAPI.current) return;

    const backToList = () => {
      navigate("/carousel-slides");
    };

    const getApiResources = async () => {
      const apiCalls = [
        api.get(`/api/carousel-slides/${params.id}`),
        api.get("/api/products"),
        api.get("/api/carousel-slides"),
      ];
      await Promise.all(apiCalls)
        .then(responses => {
          setCarouselSlide(responses[0].data);
          setProducts(responses[1].data);
          setAllSlides(responses[2].data);
        })
        .catch(e => {
          if (e.status === 404) {
            e.msg = "Slide não encontrado!";
          }
          onRequestError(e.msg, e, backToList);
        })
        .finally(() => setLoading(false));
    };

    didcallAPI.current = true;
    setLoading(true);
    getApiResources();

    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Editar Slide</h1>
      <CarouselSlideForm
        carouselSlide={carouselSlide}
        allSlides={allSlides}
        products={products}
      />
    </div>
  );
};

export default UpdateCarouselSlides;
