const Page404 = () => {
  return (
    <div style={{ width: "100vw", height: "100vh" }} className="flexbox">
      <div style={{ textAlign: "center", margin: "auto" }}>
        <h1>404</h1>
        <h2>Desculpe, essa página não existe...</h2>
        <button
          onClick={() => {
            window.history.back();
          }}
          style={{ fontSize: "20px", marginTop: "3rem" }}
        >
          Voltar
        </button>
      </div>
    </div>
  );
};

export default Page404;
