import { styled } from "styled-components";

export const ItemCardWrapper = styled.div`
  background: ${props => (props.$highlight ? "var(--green2)" : "var(--light)")};
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  input {
    margin-right: 1rem;
  }

  h4,
  p {
    pointer-events: none;
  }

  h4 {
    font-size: 18px;
  }

  p {
    margin-top: 0.25rem;
    font-size: 14px;

    &.warning {
      color: var(--red);
    }
  }

  a {
    margin-right: 1.5rem;
  }

  .btn-highlight {
    margin-left: 1.5rem;
  }

  @media only screen and (max-width: 900px) {
    span.btn-text {
      display: none;
    }

    .btn {
      padding: 0.5rem 0.75rem;
    }

    a {
      margin-right: 1rem;
    }

    .btn-highlight {
      margin-left: 1rem;
    }
  }

  @media only screen and (max-width: 520px) {
    h4 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 500px) {
    div.card-controls {
      display: block;
    }

    .btn {
      padding: 0.3rem;
    }

    a {
      margin: 0 0 1rem;
    }

    .btn-highlight {
      margin: 1rem 0 0;
    }
  }

  @media only screen and (max-width: 300px) {
    h4 {
      font-size: 14px;
    }

    p {
      font-size: 10px;
    }
  }
`;
