import React, { useEffect, useState } from "react";
import * as S from "./styles";

import { useMediaLib } from "../../contexts/MediaLibContext";
import { useLoading } from "../../contexts/LoadingContext";

import MediaLibraryCard from "../MediaLibraryCard";
import StyledCheckbox from "../StyledCheckbox";

import { api } from "../../services/api";
import {
  fireSuccessMsg,
  fireErrorMsg,
  getDateFromIsoString,
  getFileNameFromMime,
  onRequestError,
} from "../../services/functions";

import Swal from "sweetalert2";
import { FileUploader } from "react-drag-drop-files";
import fileDownload from "js-file-download";

const MediaLibrary = () => {
  const [allFolders, setAllFolders] = useState([]);
  const [levelFolders, setLevelFolders] = useState([]);
  const [levelMedia, setLevelMedia] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [openFolderId, setOpenFolderId] = useState(null);
  const [showFolderForm, setShowFolderForm] = useState(false);
  const [showMoveForm, setShowMoveForm] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMoveFolder, setSelectedMoveFolder] = useState(null);

  const { setShowMediaLib } = useMediaLib();
  const { setLoading } = useLoading();

  const endpoint = "product-media";

  useEffect(() => {
    setLoading(true);
    requestApiResources();

    // eslint-disable-next-line
  }, [openFolderId]);

  useEffect(() => {
    // Altera a string do uploader, que é definida por padrão sem espaços
    const collection = document.querySelectorAll(".file-types");
    if (collection[0]) {
      let str = "";
      const types = collection[0].innerHTML.split(",");
      types.forEach((type, i) => {
        if (i === 0) {
          str += type;
        } else {
          str += `, ${type}`;
        }
      });

      collection[0].innerHTML = str;
    }
  }, []);

  const handleClose = () => {
    setShowMediaLib(false);
  };

  const requestApiResources = async () => {
    const apiCalls = [
      api.get(`/api/${endpoint}/children/${openFolderId}`),
      api.get(`/api/media-folders/children/${openFolderId}`),
      api.get(`/api/media-folders`),
    ];

    await Promise.all(apiCalls)
      .then(responses => {
        clearSelected();
        setLevelMedia(responses[0].data);
        setLevelFolders(responses[1].data);
        setAllFolders(responses[2].data);
      })
      .catch(e => {
        if (!e.caught) {
          e.msg = "Não foi possível listar os arquivos!";
        }

        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  const clearSelected = () => {
    setSelectedMedia([]);
    setSelectedFolders([]);
  };

  const onApiSuccess = msg => {
    clearSelected();
    fireSuccessMsg(msg);
    requestApiResources();
  };

  const uploadFiles = async (imagesArray, videosArray) => {
    setLoading(true);
    await api
      .post(
        `/api/${endpoint}`,
        { images: imagesArray, videos: videosArray, parent_id: openFolderId },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then(response => onApiSuccess(response.data.msg))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao subir os arquivos!";
        }

        onRequestError(e.msg, e);
        setLoading(false);
      });
  };

  const onLocalFilesUpload = newFiles => {
    const filesToUpload = [...newFiles];
    const images = [];
    const videos = [];

    filesToUpload.forEach(file => {
      if (file.type.includes("video")) {
        videos.push(file);
      } else {
        images.push(file);
      }
    });

    uploadFiles(images, videos);
  };

  const showWarnMsg = (verb, cb, targetText) => {
    Swal.fire({
      icon: "warning",
      title: `Você tem certeza de que quer ${verb} os conteúdos selecionados${
        targetText ? targetText : ""
      }?`,
      showDenyButton: true,
      confirmButtonText: `Sim, ${verb}`,
      denyButtonText: `Cancelar`,
    }).then(result => {
      if (result.isConfirmed) {
        cb();
      } else {
        setLoading(false);
      }
    });
  };

  const downloadSingleFile = async () => {
    await api
      .get(`/api/${endpoint}/download/${selectedMedia[0].id}`, {
        responseType: "blob",
      })
      .then(response =>
        fileDownload(response.data, getFileNameFromMime(response.data.type))
      )
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao baixar o arquivo!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  const singleDelete = async type => {
    let url = "/api/";
    if (type === "media") {
      url += `${endpoint}/${selectedMedia[0].id}`;
    } else {
      url += `media-folders/${selectedFolders[0].id}`;
    }

    await api
      .delete(url)
      .then(response => onApiSuccess(response.data.msg))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao deletar o arquivo!";
        }

        onRequestError(e.msg, e);
        setLoading(false);
      });
  };

  const massDownload = async () => {
    const ids = selectedMedia.map(media => media.id);

    await api
      .post(
        `/api/${endpoint}/mass-download`,
        { ids: ids },
        {
          responseType: "blob",
        }
      )
      .then(response => fileDownload(response.data, "files.zip"))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao baixar os arquivos!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  const massDelete = async (media, folders) => {
    const promises = [];

    if (media) {
      const mediaIds = selectedMedia.map(media => media.id);
      promises.push(
        api.post(`/api/${endpoint}/mass-delete`, { ids: mediaIds })
      );
    }

    if (folders) {
      const folderIds = selectedFolders.map(folder => folder.id);
      promises.push(
        api.post(`/api/media-folders/mass-delete`, { ids: folderIds })
      );
    }

    await Promise.all(promises)
      .then(responses => {
        let msg = "Pastas e arquivos deletados com sucesso!";
        if (responses.length === 1) {
          msg = responses[0].data.msg;
        }

        onApiSuccess(msg);
      })
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao deletar os arquivos!";
        }

        onRequestError(e.msg, e);
        setLoading(false);
      });
  };

  const massMove = async (media, folders) => {
    let promises = [];

    if (folders) {
      const folderIds = selectedFolders.map(folder => folder.id);
      promises.push(
        api.post(`/api/media-folders/move`, {
          ids: folderIds,
          target_id: selectedMoveFolder.id,
        })
      );
    }

    if (media) {
      const mediaIds = selectedMedia.map(media => media.id);
      promises.push(
        api.post(`/api/${endpoint}/move`, {
          ids: mediaIds,
          target_id: selectedMoveFolder.id,
        })
      );
    }

    await Promise.all(promises)
      .then(() => {
        setSelectedMoveFolder(null);
        setShowMoveForm(false);
        onApiSuccess("Conteúdos movidos com sucesso!");
      })
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao mover os conteúdos!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  const downloadHandler = () => {
    setLoading(true);

    if (selectedMedia.length > 1) {
      massDownload();
    } else if (selectedMedia.length === 1) {
      downloadSingleFile();
    }
  };

  const deleteHandler = () => {
    const hasMedia = selectedMedia.length > 0;
    const hasFolders = selectedFolders.length > 0;

    if (!hasMedia && !hasFolders) return;

    setLoading(true);
    let cb;

    if (hasMedia && hasFolders) {
      cb = () => massDelete(true, true);
    } else if (hasMedia) {
      const isSingle = selectedMedia.length === 1;

      if (isSingle) {
        cb = () => singleDelete("media");
      } else {
        cb = () => massDelete(true, false);
      }
    } else {
      const isSingle = selectedFolders.length === 1;

      if (isSingle) {
        cb = () => singleDelete("folder");
      } else {
        cb = () => massDelete(false, true);
      }
    }

    showWarnMsg("excluir", cb);
  };

  const moveHandler = async () => {
    if (!selectedMoveFolder) {
      fireErrorMsg("Selecione uma pasta para mover os conteúdos!");
      return;
    }

    const hasMedia = selectedMedia.length > 0;
    const hasFolders = selectedFolders.length > 0;

    if (!hasMedia && !hasFolders) return;

    setLoading(true);
    showWarnMsg(
      "mover",
      () => massMove(hasMedia, hasFolders),
      ` para a pasta ${selectedMoveFolder.name}`
    );
  };

  const onSelectAll = e => {
    let newMedia = [],
      newFolders = [];

    if (e.target.checked) {
      newMedia = [...levelMedia];
      newFolders = [...levelFolders];
    }

    setSelectedMedia(newMedia);
    setSelectedFolders(newFolders);
  };

  const closeFolderForm = () => {
    setFolderName("");
    setShowFolderForm(false);
  };

  const closeMoveForm = () => {
    setSelectedMoveFolder(null);
    setShowMoveForm(false);
  };

  const openCreateFolderForm = () => {
    setIsEditing(false);
    setShowFolderForm(true);
  };

  const openEditFolderForm = () => {
    setFolderName(selectedFolders[0].name);
    setIsEditing(true);
    setShowFolderForm(true);
  };

  const folderFormHandler = async e => {
    e.preventDefault();
    setLoading(true);

    let promise;
    if (isEditing) {
      promise = api.patch(`/api/media-folders/${selectedFolders[0].id}`, {
        name: folderName,
      });
    } else {
      promise = api.post("/api/media-folders", {
        name: folderName,
        parent_id: openFolderId,
      });
    }

    await promise
      .then(response => {
        onApiSuccess(response.data.msg);
        closeFolderForm();
      })
      .catch(e => {
        if (!e.caught) {
          e.msg = `Houve um problema ao ${
            isEditing ? "editar" : "cadastrar"
          } a pasta!`;
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  const renderFolderInfo = () => {
    const folder = selectedFolders[0];

    return (
      <>
        <div>
          <p>Data de criação: {getDateFromIsoString(folder.created_at)}</p>
          {folder.subfolders.length === 0 && folder.media.length === 0 && (
            <p className="alert">Não utilizada!</p>
          )}
        </div>

        {renderControls()}
      </>
    );
  };

  const renderMediaInfo = () => {
    const media = selectedMedia[0];
    const url = `${api.getUri()}/storage/${selectedMedia[0].path}`;

    return (
      <>
        <div>
          <div className="preview">
            {media.media_type === "Image" ? (
              <img src={url} alt="" />
            ) : (
              <video controls>
                <source
                  src={url}
                  type={`video/${media.path.split(".")[1]}`}
                ></source>
              </video>
            )}
          </div>

          <p>Data do upload: {getDateFromIsoString(media.created_at)}</p>
          {media.products.length === 0 && (
            <p className="alert">Não utilizada!</p>
          )}
        </div>

        {renderControls()}
      </>
    );
  };

  const controlsHandler = () => {
    const hasMedia = selectedMedia.length > 0;
    const hasFolders = selectedFolders.length > 0;

    if (hasMedia && hasFolders) {
      return renderMixedControls();
    } else if (hasMedia) {
      return renderMediaControls();
    } else {
      return renderFolderControls();
    }
  };

  const renderControls = () => {
    return (
      <div
        className="flexbox v-centered h-centered controls"
        style={{ marginTop: "2rem" }}
      >
        {controlsHandler()}
      </div>
    );
  };

  const renderMediaControls = () => {
    return (
      <>
        <button
          type="button"
          className="btn blue md"
          onClick={() => setShowMoveForm(true)}
        >
          <span className="material-symbols-rounded">move_group</span>
          <span style={{ marginLeft: "0.5rem" }}>Mover...</span>
        </button>

        <button type="button" className="btn blue md" onClick={downloadHandler}>
          <span className="material-symbols-rounded">download</span>
          <span style={{ marginLeft: "0.5rem" }}>Download</span>
        </button>

        <button type="button" className="btn red md" onClick={deleteHandler}>
          <span className="material-symbols-rounded">delete</span>
          <span style={{ marginLeft: "0.5rem" }}>Excluir</span>
        </button>
      </>
    );
  };

  const renderFolderControls = () => {
    const isSingle = selectedFolders.length === 1;

    return (
      <>
        <button
          type="button"
          className="btn blue md"
          onClick={() => setShowMoveForm(true)}
        >
          <span className="material-symbols-rounded">move_group</span>
          <span style={{ marginLeft: "0.5rem" }}>Mover...</span>
        </button>

        {isSingle && (
          <button
            type="button"
            className="btn blue md"
            onClick={openEditFolderForm}
          >
            <span className="material-symbols-rounded">edit</span>
            <span style={{ marginLeft: "0.5rem" }}>Editar</span>
          </button>
        )}

        <button type="button" className="btn red md" onClick={deleteHandler}>
          <span className="material-symbols-rounded">delete</span>
          <span style={{ marginLeft: "0.5rem" }}>Excluir</span>
        </button>
      </>
    );
  };

  const renderMixedControls = () => {
    return (
      <>
        <button
          type="button"
          className="btn blue md"
          onClick={() => setShowMoveForm(true)}
        >
          <span className="material-symbols-rounded">move_group</span>
          <span style={{ marginLeft: "0.5rem" }}>Mover...</span>
        </button>

        <button type="button" className="btn red md" onClick={deleteHandler}>
          <span className="material-symbols-rounded">delete</span>
          <span style={{ marginLeft: "0.5rem" }}>Excluir</span>
        </button>
      </>
    );
  };

  const renderMultipleFoldersWarn = () => {
    return (
      <>
        <div>
          <p>Várias pastas selecionadas</p>
        </div>

        {renderControls()}
      </>
    );
  };

  const renderMultipleMediaWarn = () => {
    return (
      <>
        <div>
          <p>Várias mídias selecionadas</p>
        </div>

        {renderControls()}
      </>
    );
  };

  const renderMultipleWarn = () => {
    return (
      <>
        <div>
          <p>Conteúdos diversos selecionados</p>
        </div>

        {renderControls()}
      </>
    );
  };

  const renderInfos = () => {
    const hasMedia = selectedMedia.length > 0;
    const hasFolders = selectedFolders.length > 0;

    if (!hasMedia && !hasFolders) {
      return null;
    } else if (hasMedia && hasFolders) {
      return renderMultipleWarn();
    } else if (hasMedia) {
      const isSingle = selectedMedia.length === 1;

      if (isSingle) {
        return renderMediaInfo();
      } else {
        return renderMultipleMediaWarn();
      }
    } else {
      const isSingle = selectedFolders.length === 1;

      if (isSingle) {
        return renderFolderInfo();
      } else {
        return renderMultipleFoldersWarn();
      }
    }
  };

  const folderHasUnusedContent = data => {
    const index = data.media?.findIndex(media => media.products.length === 0);
    return index !== undefined && index !== -1;
  };

  const renderFolderLink = (data, isMoveForm) => {
    let hasUnusedContent = false;
    if (!isMoveForm) {
      hasUnusedContent = folderHasUnusedContent(data);
    }

    let btnClass = undefined;
    if (
      (isMoveForm && data.id === selectedMoveFolder?.id) ||
      (!isMoveForm && data.id === openFolderId)
    ) {
      btnClass = "is-active";
    }

    const handler = () => {
      if (isMoveForm) {
        setSelectedMoveFolder(data);
      } else {
        setOpenFolderId(data.id);
      }
    };

    return (
      <button type="button" onClick={handler} className={btnClass}>
        {data.name}
        {hasUnusedContent && (
          <span style={{ color: "var(--red)", fontWeight: "bolder" }}> !</span>
        )}
      </button>
    );
  };

  const renderFolderLinkLevel = (data, isMoveForm) => {
    const hasSubs = data.subfolders.length > 0;

    return (
      <li key={data.id}>
        {renderFolderLink(data, isMoveForm)}
        {hasSubs && (
          <ul>
            {data.subfolders.map(sub => renderFolderLinkLevel(sub, isMoveForm))}
          </ul>
        )}
      </li>
    );
  };

  const renderNavTree = isMoveForm => {
    return (
      <ul>
        <li>{renderFolderLink({ id: null, name: "/" }, isMoveForm)}</li>
        <ul>
          {allFolders.map(folder => renderFolderLinkLevel(folder, isMoveForm))}
        </ul>
      </ul>
    );
  };

  return (
    <S.Overlay>
      <S.MediaLibWrapper>
        <S.MediaLibTitle className="flexbox v-centered h-spaced-between">
          <h2>Biblioteca de Mídia</h2>
          <button type="button" onClick={handleClose}>
            <span className="material-symbols-rounded">close</span>
          </button>
        </S.MediaLibTitle>

        <FileUploader
          multiple
          maxSize={10}
          types={[
            "jpg",
            "jpeg",
            "png",
            "webp",
            "mp4",
            "mpeg",
            "wmv",
            "avi",
            "webm",
          ]}
          classes="file-uploader"
          label="Solte seus arquivos aqui ou clique para selecionar..."
          hoverTitle="Solte aqui"
          dropMessageStyle={{
            backgroundColor: "var(--blue)",
            color: "var(--lighter)",
          }}
          onTypeError={() =>
            fireErrorMsg(
              "Tipo de arquivo não compatível! Tipos aceitos: jpg, jpeg, png, webp, mp4, mpeg, wmv, avi, webm"
            )
          }
          onSizeError={() =>
            fireErrorMsg("Arquivo grande demais! Tamanho máximo: 10MB")
          }
          handleChange={onLocalFilesUpload}
        />

        <div
          className="flexbox v-centered selection-controls"
          style={{ marginBottom: "1rem" }}
        >
          {levelMedia.length > 0 && (
            <div className="flexbox" style={{ marginRight: "1rem" }}>
              <StyledCheckbox
                id="inp_select_all"
                handler={onSelectAll}
                style={{ margin: "0 1rem" }}
              />
              <label htmlFor="inp_select_all" style={{ marginBottom: 0 }}>
                Selecionar todos
              </label>
            </div>
          )}

          <button
            type="button"
            className="btn blue"
            onClick={openCreateFolderForm}
          >
            <span className="material-symbols-rounded">add</span>
            <span style={{ fontSize: 16, marginLeft: "0.5rem" }}>
              Nova pasta
            </span>
          </button>
        </div>

        <S.MediaLibContent>
          <S.MediaLibGallery>
            {levelFolders.map((folder, i) => (
              <MediaLibraryCard
                key={i}
                data={folder}
                selectState={selectedFolders}
                selectHandler={setSelectedFolders}
              />
            ))}

            {levelMedia.map((item, i) => (
              <MediaLibraryCard
                key={i}
                data={item}
                selectState={selectedMedia}
                selectHandler={setSelectedMedia}
              />
            ))}
          </S.MediaLibGallery>

          <S.MediaLibInfo>
            <h3>Informações</h3>
            {renderInfos()}
          </S.MediaLibInfo>

          <S.MediaLibInfo className="sm">
            <h3>Pastas</h3>
            {renderNavTree(false)}
          </S.MediaLibInfo>
        </S.MediaLibContent>
      </S.MediaLibWrapper>

      {showFolderForm && (
        <S.Overlay>
          <S.FolderForm onSubmit={folderFormHandler}>
            <h3>{`${isEditing ? "Editar" : "Cadastrar"} Pasta`}</h3>

            <label htmlFor="inp_name">Nome</label>
            <input
              id="inp_name"
              type="text"
              max={128}
              value={folderName}
              onChange={e => setFolderName(e.target.value)}
            />

            <div className="controls">
              <button className="btn confirm">{`${
                isEditing ? "Editar" : "Cadastrar"
              } pasta`}</button>
              <button
                className="btn cancel"
                type="button"
                onClick={closeFolderForm}
              >
                Cancelar
              </button>
            </div>
          </S.FolderForm>
        </S.Overlay>
      )}

      {showMoveForm && (
        <S.Overlay>
          <S.FolderForm>
            <h3>Mover Conteúdo</h3>
            <h4>Selecione a pasta de destino:</h4>

            <div>{renderNavTree(true)}</div>

            <div className="controls">
              <button
                className="btn confirm"
                type="button"
                onClick={moveHandler}
              >
                Confirmar
              </button>
              <button
                className="btn cancel"
                type="button"
                onClick={closeMoveForm}
              >
                Cancelar
              </button>
            </div>
          </S.FolderForm>
        </S.Overlay>
      )}
    </S.Overlay>
  );
};

export default MediaLibrary;
