import { styled } from "styled-components";

export const CarouselSlideFormWrapper = styled.form`
  background: var(--light);
  padding: 1.5rem 1rem;
  border-radius: 10px;

  & > div {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &.flexbox > div {
      width: 100%;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }
    }
  }

  .file-uploader {
    width: 100%;
    height: 85px;
    max-width: 100%;
    min-width: none;

    .file-types {
      max-width: none;
    }

    span {
      font-size: 16px;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 700px) {
    .file-uploader span {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 560px) {
    & > div.flexbox > div:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 520px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 460px) {
    & > div.flexbox {
      display: block;

      & > div:not(:last-of-type) {
        margin: 0 0 1rem;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .file-uploader {
      .file-types {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 300px) {
    font-size: 12px;
  }
`;
