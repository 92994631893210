import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as S from "./styles";

import { api } from "../../services/api";
import { useLoading } from "../../contexts/LoadingContext";
import { useAuth } from "../../contexts/AuthContext";

const UserMenu = ({ user }) => {
  const [normalizedName, setNormalizedName] = useState("");
  const [initials, setInitials] = useState("");

  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { setUser } = useAuth();

  useEffect(() => {
    if (user.name) {
      const names = user.name.split(" ");
      const str = `${names[0]} ${names[names.length - 1]}`;
      setNormalizedName(str);
    }
  }, [user]);

  useEffect(() => {
    if (normalizedName) {
      const names = normalizedName.split(" ");
      const str = names[0][0] + names[1][0];
      setInitials(str);
    }
  }, [normalizedName]);

  const selectHandler = () => {
    const div = document.getElementById("dropdown");
    const arrow = document.getElementById("profile-arrow");
    const arrowMobile = document.getElementById("profile-arrow-mobile");

    div.classList.toggle("active");
    arrow?.classList.toggle("active");
    arrowMobile?.classList.toggle("active");
  };

  const logoutHandler = async () => {
    setLoading(true);
    await api
      .post("/logout")
      .then(() => {
        setUser(null);
        navigate("/");
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  };

  const getNormalizedRole = role => {
    switch (role) {
      case "admin":
        return "Admin";

      case "user":
        return "Usuário";

      default:
        return "Visitante";
    }
  };

  return (
    <S.UserWrapper onClick={selectHandler}>
      <S.UserDropdown id="dropdown">
        <Link to={`/users/edit/${user.id}`}>
          <span className="material-symbols-rounded">edit</span>Editar perfil
        </Link>
        <button onClick={logoutHandler}>
          <span className="material-symbols-rounded">logout</span>Sair da conta
        </button>
      </S.UserDropdown>

      <div className="flexbox v-centered container">
        <div className="flexbox v-centered">
          <span id="profile-arrow" className="material-symbols-rounded">
            expand_more
          </span>
          <div>
            <p>{normalizedName}</p>
            <p>{getNormalizedRole(user.role)}</p>
          </div>
        </div>

        <div className="flexbox v-centered">
          <span id="profile-arrow-mobile" className="material-symbols-rounded">
            expand_more
          </span>
          <div className="avatar">{initials}</div>
        </div>
      </div>
    </S.UserWrapper>
  );
};

const Clock = () => {
  useEffect(() => {
    const getCurrentTime = () => {
      const el = document.getElementById("clock");
      if (!el || !el.parentElement.checkVisibility()) return;

      const time = new Date().toLocaleDateString("pt-BR", {
        weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      el.innerHTML = time;
      setTimeout(getCurrentTime, 1000 * 1);
    };

    getCurrentTime();
  }, []);

  return (
    <S.ClockWrapper>
      <span className="material-symbols-rounded">schedule</span>
      <span id="clock"></span>
    </S.ClockWrapper>
  );
};

const Header = ({ user }) => {
  return (
    <S.HeaderWrapper>
      <div className="title">Painel de controle</div>
      <div className="flexbox v-centered user-tools">
        <Clock />
        <UserMenu user={user} />
      </div>
    </S.HeaderWrapper>
  );
};

export default Header;
