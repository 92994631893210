import { useEffect, useState } from "react";
import * as S from "./styles";

import { api } from "../../services/api";

const ImgPreviewCard = ({
  id,
  path,
  localDeleteHandler,
  remoteDeleteHandler,
  remoteDownloadHandler,
}) => {
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (!path) return;

    if (!id) {
      setImgSrc(path);
    } else {
      setImgSrc(`${api.getUri()}/storage/${path}`);
    }
  }, [id, path]);

  const deleteHandler = () => {
    if (!id) {
      localDeleteHandler(null, path);
    } else {
      remoteDeleteHandler(id);
    }
  };

  const downloadHandler = () => {
    remoteDownloadHandler(id);
  };

  return (
    imgSrc && (
      <S.ImgPreviewCardWrapper2>
        <img src={imgSrc} alt="" />

        <div>
          <button type="button" className="del" onClick={deleteHandler}>
            <span className="material-symbols-rounded">delete</span>
          </button>
          {id && (
            <button
              type="button"
              className="download"
              onClick={downloadHandler}
            >
              <span className="material-symbols-rounded">download</span>
            </button>
          )}
        </div>
      </S.ImgPreviewCardWrapper2>
    )
  );
};

export default ImgPreviewCard;
