import { styled } from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9;
  padding: 3rem;

  @media only screen and (max-width: 1200px) {
    padding: 1rem;
  }
`;

export const MediaLibWrapper = styled.div`
  background: var(--light);
  width: calc(100vw - 6rem);
  height: calc(100vh - 6rem);
  padding: 2rem;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: hidden;

  .file-uploader {
    width: 100%;
    height: 85px;
    max-width: 100%;
    min-width: auto;
    margin-bottom: 2rem;

    .file-types {
      max-width: none;
      white-space: normal;
    }

    span {
      font-size: 16px;
    }

    svg {
      margin: 0 0.5rem 0 0;
    }

    & > div {
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 1rem;
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);

    .file-uploader {
      & > div {
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .file-uploader {
      height: auto;

      & > div {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .file-uploader span {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 500px) {
    .file-uploader {
      flex-direction: column;

      svg {
        margin: 0 0 0.5rem;
      }

      span {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    div.selection-controls {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
`;

export const MediaLibTitle = styled.div`
  margin-bottom: 2rem;

  button {
    display: block;
    padding: 0;

    span {
      font-size: 32px;
    }
  }
`;

export const MediaLibContent = styled.div`
  width: 100%;
  height: calc(100vh - 24.5rem);
  display: flex;
  gap: 1.5rem;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    height: auto;
  }
`;

export const MediaLibGallery = styled.div`
  width: 100%;
  border: 1px solid var(--grey1);
  border-radius: 10px;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const MediaLibInfo = styled.div`
  background: var(--grey6);
  width: 30%;
  border: 1px solid var(--grey1);
  border-radius: 10px;
  padding: 1rem;
  overflow-y: auto;

  &.sm {
    width: 20%;
    overflow: auto;
  }

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0.5rem;

    &.alert {
      color: var(--red);
      text-decoration: underline;
    }
  }

  div.preview {
    margin-bottom: 1rem;

    img,
    video {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 370px;
    }
  }

  div.controls {
    gap: 1rem;
    flex-direction: column;
  }

  ul {
    padding-inline-start: 25px;
    margin-top: 0.25rem;
    list-style-type: disc;

    button {
      font-family: "Be Vietnam Pro", sans-serif;
      font-size: 14px;
      white-space: nowrap;

      &.is-active {
        color: var(--blue2);
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    li:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;

    &.sm {
      width: 100%;
    }

    div.controls {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 450px) {
    div.controls {
      flex-direction: column;
    }
  }
`;

export const FolderForm = styled.form`
  background: var(--ice);
  border-radius: 10px;
  padding: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 50%;
  max-height: calc(100vh - 10rem);
  overflow: auto;

  h3 {
    margin-bottom: 1rem;
  }

  div.controls {
    margin-top: 2rem;
    display: flex;

    button:not(:last-of-type) {
      margin-right: 2rem;
    }
  }

  ul {
    padding-inline-start: 25px;
    margin-top: 0.5rem;
    list-style-type: disc;

    button {
      font-family: "Be Vietnam Pro", sans-serif;
      font-size: 16px;
      white-space: nowrap;

      &.is-active {
        color: var(--blue2);
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    li {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    max-width: 85%;
    max-height: calc(100vh - 6rem);
  }
`;
