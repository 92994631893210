import React, { useEffect, useState } from "react";
import * as S from "../styles/guestPages";

import { api } from "../services/api";
import { fireSuccessMsg, onRequestError } from "../services/functions";
import { useLoading } from "../contexts/LoadingContext";

import { useNavigate, useLocation, Link } from "react-router-dom";

const PasswordForgot = () => {
  const [email, setEmail] = useState("");

  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const passedEmail = location?.state?.email;
    if (passedEmail) {
      setEmail(passedEmail);
    }
  }, [location]);

  const onSuccess = response => {
    if (response.status === 200) {
      navigate("/");
      fireSuccessMsg("Solicitação feita com sucesso! Confira seu email");
    } else {
      onRequestError(
        "Algo deu errado! Contate o administrador do sistema",
        response
      );
    }
  };

  const handleToken = e => {
    e.preventDefault();
    setLoading(true);

    api
      .post("/forgot-password", { email: email })
      .then(response => onSuccess(response))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao solicitar a redefinição da senha!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.FormWrapper onSubmit={handleToken}>
      <div>
        <h3 style={{ marginBottom: "0.5rem" }}>Esqueceu sua senha?</h3>
        <h4>Não se preocupe. Entraremos em contato por email.</h4>
      </div>

      <div className="m-sm">
        <label htmlFor="inp_email">Email</label>
        <input
          id="inp_email"
          type="email"
          placeholder="Digite o email cadastrado"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
      </div>

      <div style={{ textAlign: "end" }}>
        <Link to={"/"} state={{ email: email }}>
          Voltar para login
        </Link>
      </div>

      <button type="submit" className="btn blue lg full">
        Solicitar redefinição de senha
      </button>
    </S.FormWrapper>
  );
};

export default PasswordForgot;
