import { styled } from "styled-components";

export const BreadcrumbsWrapper = styled.div`
  color: var(--lead);
  font-size: 14px;
  width: fit-content;

  a {
    color: var(--lead);
    text-transform: capitalize;

    &:hover,
    &.selected {
      color: var(--yellow);
      text-decoration: underline;
    }

    &.selected {
      font-size: 18px;
      pointer-events: none;
    }
  }

  @media only screen and (max-width: 520px) {
    font-size: 12px;

    a.selected,
    span[class^="material-symbols"] {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 300px) {
    font-size: 10px;

    a.selected,
    span[class^="material-symbols"] {
      font-size: 14px;
    }
  }
`;
