import React, { useEffect, useState } from "react";
import * as S from "../styles/guestPages";

import StyledCheckbox from "../components/StyledCheckbox";

import { api } from "../services/api";
import { onRequestError } from "../services/functions";
import { useLoading } from "../contexts/LoadingContext";

import { useNavigate, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const passedEmail = location?.state?.email;
    if (passedEmail) {
      setEmail(passedEmail);
    }
  }, [location]);

  const copyEmail = async email => {
    await navigator.clipboard.writeText(email).then(() => {
      Swal.fire({
        text: "Copiado para a área de transferência!",
        position: "top-end",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    });
  };

  const onSuccess = () => {
    navigate("/home");
  };

  const handleLogin = async () => {
    await api
      .post("/login", {
        email: email,
        password: password,
        remember: remember,
      })
      .then(onSuccess)
      .catch(e => {
        if (e.status === 422) {
          e.msg = "Email e/ou senha inválidos!";
        } else if (!e.caught) {
          e.msg = "Não foi possível realizar o login. Tente novamente.";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  const handleCSRF = async e => {
    e.preventDefault();
    setLoading(true);

    await api
      .get("/sanctum/csrf-cookie")
      .then(handleLogin)
      .catch(e => {
        setLoading(false);
        onRequestError(e.msg, e);
      });
  };

  return (
    <S.FormWrapper onSubmit={handleCSRF}>
      <div>
        <h3>Bem-vindo!</h3>
      </div>

      <div>
        <label htmlFor="inp_email">Email</label>
        <input
          id="inp_email"
          type="email"
          placeholder="Digite seu email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="m-sm">
        <label htmlFor="inp_pass">Senha</label>
        <div style={{ position: "relative" }}>
          <input
            id="inp_pass"
            type={passwordVisibility ? "text" : "password"}
            placeholder="Digite sua senha"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <span
            className="material-symbols-rounded eye-btn"
            onClick={() => setPasswordVisibility(!passwordVisibility)}
          >
            {passwordVisibility ? "visibility_off" : "visibility"}
          </span>
        </div>
      </div>

      <div className="flexbox v-centered h-spaced-between">
        <div id="check-container" className="flexbox v-centered">
          <StyledCheckbox
            id="inp_remember"
            state={remember}
            handler={e => setRemember(e.target.checked)}
          />
          <label htmlFor="inp_remember">Manter conectado</label>
        </div>
        <div>
          <Link to={"/forgot-password"} state={{ email: email }}>
            Esqueci minha senha
          </Link>
        </div>
      </div>

      <button type="submit" className="btn blue lg full">
        Login
      </button>

      <p>
        Precisa de acesso? Solicite no email{" "}
        <span className="email" onClick={e => copyEmail(e.target.innerText)}>
          gta@gtaguaritas.com.br
        </span>
      </p>
    </S.FormWrapper>
  );
};

export default Signin;
