import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

import FormButtons from "../FormButtons";
import { api } from "../../services/api";
import { fireSuccessMsg, onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const ModelTypeForm = ({ modelType }) => {
  const [name, setName] = useState("");

  const navigate = useNavigate();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (modelType) {
      setName(modelType.name);
    }
  }, [modelType]);

  const backToList = () => {
    navigate("/model-types");
  };

  const onSuccess = (msg, isEdit) => {
    const cb = isEdit ? backToList : null;

    fireSuccessMsg(msg, cb);
    setName("");
  };

  const createNewModelType = async () => {
    await api
      .post("/api/model-types", { name: name })
      .then(response => onSuccess(response.data.msg))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao cadastrar o modelo!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editModelType = async () => {
    await api
      .patch(`/api/model-types/${modelType.id}`, { name: name })
      .then(response => onSuccess(response.data.msg, true))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao editar o modelo!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitHandler = e => {
    e.preventDefault();
    setLoading(true);
    if (modelType) {
      editModelType();
    } else {
      createNewModelType();
    }
  };

  return (
    <S.ModelTypeFormWrapper onSubmit={submitHandler}>
      <label htmlFor="inp_name">Nome</label>
      <input
        id="inp_name"
        type="text"
        placeholder="Nome do modelo"
        maxLength={45}
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />
      <FormButtons cancelCallback={backToList} />
    </S.ModelTypeFormWrapper>
  );
};

export default ModelTypeForm;
