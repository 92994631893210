import { createBrowserRouter } from "react-router-dom";

import SignedLayout from "./layouts/SignedLayout";
import GuestLayout from "./layouts/GuestLayout";
import ConfirmationLayout from "./layouts/ConfirmationLayout";

import Page404 from "./pages/404";
import Signin from "./pages/Signin";
import PasswordForgot from "./pages/PasswordForgot";
import PasswordReset from "./pages/PasswordReset";
import ConfirmAccount from "./pages/ConfirmAccount";
import AccountReady from "./pages/AccountReady";
import Home from "./pages/Home";

import ListModelTypes from "./pages/ModelTypes/list";
import ListOptionals from "./pages/Optionals/list";
import ListProducts from "./pages/Products/list";
import ListUsers from "./pages/Users/list";
import ListCarouselSlides from "./pages/CarouselSlides/list";

import CreateModelTypes from "./pages/ModelTypes/create";
import CreateOptionals from "./pages/Optionals/create";
import CreateProducts from "./pages/Products/create";
import CreateUsers from "./pages/Users/create";
import CreateCarouselSlides from "./pages/CarouselSlides/create";

import UpdateModelTypes from "./pages/ModelTypes/update";
import UpdateOptionals from "./pages/Optionals/update";
import UpdateProducts from "./pages/Products/update";
import UpdateUsers from "./pages/Users/update";
import UpdateCarouselSlides from "./pages/CarouselSlides/update";

const MainRouter = createBrowserRouter([
  { path: "*", element: <Page404 /> },

  {
    path: "/",
    element: <GuestLayout />,
    children: [{ path: "", element: <Signin /> }],
  },

  {
    path: "/forgot-password",
    element: <GuestLayout />,
    children: [{ path: "", element: <PasswordForgot /> }],
  },

  {
    path: "/reset-password/:token",
    element: <GuestLayout />,
    children: [{ path: "", element: <PasswordReset /> }],
  },

  {
    path: "/account-ready",
    element: <AccountReady />,
  },

  {
    path: "/confirm-account",
    element: <ConfirmationLayout />,
    children: [{ path: "", element: <ConfirmAccount /> }],
  },

  {
    path: "/home",
    element: <SignedLayout />,
    children: [{ path: "", element: <Home /> }],
  },

  {
    path: "/model-types",
    element: <SignedLayout />,
    children: [
      { path: "", element: <ListModelTypes /> },
      { path: "register", element: <CreateModelTypes /> },
      { path: "edit/:id", element: <UpdateModelTypes /> },
    ],
  },

  {
    path: "/optionals",
    element: <SignedLayout />,
    children: [
      { path: "", element: <ListOptionals /> },
      { path: "register", element: <CreateOptionals /> },
      { path: "edit/:id", element: <UpdateOptionals /> },
    ],
  },

  {
    path: "/products",
    element: <SignedLayout />,
    children: [
      { path: "", element: <ListProducts /> },
      { path: "register", element: <CreateProducts /> },
      { path: "edit/:id", element: <UpdateProducts /> },
    ],
  },

  {
    path: "/users",
    element: <SignedLayout />,
    children: [
      { path: "", element: <ListUsers /> },
      { path: "register", element: <CreateUsers /> },
      { path: "edit/:id", element: <UpdateUsers /> },
    ],
  },

  {
    path: "/carousel-slides",
    element: <SignedLayout />,
    children: [
      { path: "", element: <ListCarouselSlides /> },
      { path: "register", element: <CreateCarouselSlides /> },
      { path: "edit/:id", element: <UpdateCarouselSlides /> },
    ],
  },
]);

export default MainRouter;
