import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

import FormButtons from "../FormButtons";
import ImgPreviewCard from "../ImgPreviewCard";

import { api } from "../../services/api";
import {
  fireSuccessMsg,
  fireErrorMsg,
  onRequestError,
} from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

import Swal from "sweetalert2";
import { FileUploader } from "react-drag-drop-files";

const CarouselSlideForm = ({ carouselSlide, allSlides, products }) => {
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [productId, setProductId] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [linkLabel, setLinkLabel] = useState("");
  const [pagesToShow, setPagesToShow] = useState("");
  const [order, setOrder] = useState(0);
  const [previewImage, setPreviewImage] = useState();
  const [file, setFile] = useState();

  const navigate = useNavigate();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (carouselSlide) {
      // console.log("TESTE carouselSlide", carouselSlide);
      setName(carouselSlide.name);
      setText(carouselSlide.text);
      setProductId(carouselSlide.product_id);
      setLinkUrl(carouselSlide.link_url);
      setLinkLabel(carouselSlide.link_label);
      setPagesToShow(carouselSlide.pages_to_show);
      setOrder(carouselSlide.order);
      setPreviewImage(carouselSlide.image);
    }
  }, [carouselSlide]);

  const backToList = () => {
    navigate("/carousel-slides");
  };

  const refreshPage = () => {
    navigate(0);
  };

  const onSuccess = (msg, isEdit) => {
    const cb = isEdit ? backToList : refreshPage;

    fireSuccessMsg(msg, cb);
    setName("");
    setText("");
    setProductId("");
    setLinkUrl("");
    setLinkLabel("");
    setPagesToShow("");
    setOrder(0);
    setPreviewImage();
    setFile();
  };

  const createNewCarouselSlide = async () => {
    await api
      .post(
        "/api/carousel-slides",
        {
          name: name,
          text: text,
          product_id: isNaN(parseInt(productId)) ? null : parseInt(productId),
          link_url: linkUrl ? linkUrl : null,
          link_label: linkLabel ? linkLabel : null,
          pages_to_show: pagesToShow ? pagesToShow : null,
          order: order,
          image: file,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then(response => onSuccess(response.data.msg))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao cadastrar o slide!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCarouselSlide = async () => {
    await api
      .post(
        `/api/carousel-slides/${carouselSlide.id}?_method=PATCH`,
        {
          name: name,
          text: text,
          product_id: isNaN(parseInt(productId)) ? null : parseInt(productId),
          link_url: linkUrl ? linkUrl : null,
          link_label: linkLabel ? linkLabel : null,
          pages_to_show: pagesToShow ? pagesToShow : null,
          order: order,
          image: file,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then(response => onSuccess(response.data.msg, true))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao editar o slide!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitHandler = e => {
    e.preventDefault();
    setLoading(true);
    if (carouselSlide) {
      editCarouselSlide();
    } else {
      createNewCarouselSlide();
    }
  };

  const removeImgPreview = () => {
    if (previewImage.originalFile) {
      removeFile(previewImage.originalFile);
    }

    setPreviewImage();
  };

  const createImgPreview = result => {
    const preview = {
      id: null,
      path: result.data,
      originalFile: result.originalFile,
    };
    setPreviewImage(preview);
  };

  const readFile = file => {
    return new Promise(resolve => {
      let reader = new FileReader();
      reader.onload = () =>
        resolve({ data: reader.result, originalFile: file });
      reader.readAsDataURL(file);
    });
  };

  const onFileUpload = newFile => {
    readFile(newFile).then(response => createImgPreview(response));
    setFile(newFile);
  };

  const removeFile = file => {
    setFile();
  };

  const showDeleteMsg = id => {
    Swal.fire({
      icon: "warning",
      title: "Você tem certeza de que quer excluir a imagem salva?",
      showDenyButton: true,
      confirmButtonText: "Sim, excluir",
      denyButtonText: `Cancelar`,
    }).then(result => {
      if (result.isConfirmed) {
        deleteFromStorage(id);
      }
    });
  };

  const deleteFromStorage = async id => {
    setLoading(true);
    await api
      .delete(`/api/slide-images/${id}`)
      .then(response => {
        fireSuccessMsg(response.data.msg);
        removeImgPreview();
      })
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao excluir a imagem!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.CarouselSlideFormWrapper onSubmit={submitHandler}>
      <div>
        <label htmlFor="inp_name">Título</label>
        <input
          id="inp_name"
          type="text"
          placeholder="Título do slide"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_text">Texto</label>
        <textarea
          id="inp_text"
          placeholder="Texto do slide"
          value={text}
          onChange={e => setText(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_order">Ordem</label>
        <select
          id="inp_order"
          name="order"
          value={order}
          onChange={e => setOrder(e.target.value)}
          required
        >
          <option value={0}>0</option>
          {allSlides?.map((slide, i) => (
            <option value={i + 1}>{i + 1}</option>
          ))}
        </select>
      </div>

      {products?.length > 0 && (
        <div>
          <label htmlFor="inp_product_id">Produto (opcional)</label>
          <select
            id="inp_product_id"
            name="product_id"
            value={productId}
            onChange={e => setProductId(e.target.value)}
          >
            <option value={""}>Nenhum</option>
            {products.map((prod, i) => (
              <option key={i} value={prod.id}>
                {`${prod.name} ${prod.width} x ${prod.length}`}
              </option>
            ))}
          </select>
        </div>
      )}

      <div>
        <label htmlFor="inp_name">URL para link (opcional)</label>
        <input
          id="inp_link"
          type="text"
          placeholder="URL do link"
          value={linkUrl}
          onChange={e => setLinkUrl(e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="inp_name">Texto para link (opcional)</label>
        <input
          id="inp_link_label"
          type="text"
          placeholder="Texto do link"
          value={linkLabel}
          onChange={e => setLinkLabel(e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="inp_name">Páginas (opcional)</label>
        <input
          id="inp_pages"
          type="text"
          placeholder="URL das páginas, separadas por vírgula"
          value={pagesToShow}
          onChange={e => setPagesToShow(e.target.value)}
        />
      </div>

      <div>
        <p>Imagem do slide</p>
        <div style={{ marginTop: "0.5rem" }}>
          {previewImage ? (
            <ImgPreviewCard
              id={previewImage.id}
              path={previewImage.path}
              localDeleteHandler={removeImgPreview}
              remoteDeleteHandler={showDeleteMsg}
            />
          ) : (
            <p>Este slide não possui imagem</p>
          )}
        </div>
      </div>

      <FileUploader
        maxSize={2}
        types={["jpg", "jpeg", "png", "webp"]}
        classes="file-uploader"
        label="Solte seu arquivo aqui ou clique para selecionar..."
        hoverTitle="Solte aqui"
        dropMessageStyle={{
          backgroundColor: "var(--blue)",
          color: "var(--lighter)",
        }}
        onTypeError={() =>
          fireErrorMsg(
            "Tipo de arquivo não compatível! Tipos aceitos: jpg, jpeg, png"
          )
        }
        onSizeError={() =>
          fireErrorMsg("Imagem grande demais! Tamanho máximo: 2MB")
        }
        handleChange={onFileUpload}
      />

      <FormButtons cancelCallback={backToList} />
    </S.CarouselSlideFormWrapper>
  );
};

export default CarouselSlideForm;
