import { styled } from "styled-components";

export const PageWrapper = styled.div`
  background: var(--light2);
  max-width: 80%;
  min-height: 100vh;
  margin-left: auto;

  @media only screen and (max-width: 750px) {
    max-width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  top: 6rem;
  padding-top: 3rem;
  background: var(--light2);

  .add,
  btn-control {
    text-align: center;
  }

  .btn-control {
    margin-left: 2rem;
  }

  @media only screen and (max-width: 1300px) {
    .btn-control {
      margin-left: 1rem;
    }
  }

  @media only screen and (max-width: 1180px) {
    div.controls {
      display: block;
    }

    .add,
    .btn-control {
      width: 100%;
    }

    .btn-control {
      margin: 2rem 0 0;
    }
  }

  @media only screen and (max-width: 750px) {
    padding: 1.5rem !important;
  }

  @media only screen and (max-width: 700px) {
    top: 4.5rem;
  }

  @media only screen and (max-width: 520px) {
    h1 {
      font-size: 28px;
    }
  }

  @media only screen and (max-width: 300px) {
    padding: 1.5rem 1rem !important;

    h1 {
      font-size: 24px;
    }
  }
`;
