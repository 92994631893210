import { styled } from "styled-components";

export const ProductFormWrapper = styled.form`
  background: var(--light);
  padding: 1.5rem 1rem;
  border-radius: 10px;

  & > div {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &.flexbox > div {
      width: 100%;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }
    }
  }

  div.file-select {
    gap: 1rem;
  }

  @media only screen and (max-width: 900px) {
    div.file-select {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 560px) {
    & > div.flexbox > div:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 520px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 460px) {
    & > div.flexbox {
      display: block;

      & > div:not(:last-of-type) {
        margin: 0 0 1rem;
      }
    }
  }

  @media only screen and (max-width: 300px) {
    font-size: 12px;
  }
`;

export const ProductCheckboxWrapper = styled.div`
  background: var(--lighter);
  padding: 1.5rem 1rem;
  margin-top: 0.5rem;
  max-height: 20rem;
  width: 100%;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid var(--dark);
  display: grid;
  gap: 1rem 0.5rem;
  grid-template-columns: repeat(4, 1fr);

  ul {
    padding-inline-start: 15px;
    margin-top: 0.25rem;
    list-style-type: disc;

    button {
      font-family: "Be Vietnam Pro", sans-serif;
      font-size: 14px;

      &.is-active {
        color: var(--blue2);
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    li:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }

  &.uneven {
    display: block;

    & > div.container {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem 2rem;
    }
  }

  &.single-col {
    overflow-x: auto;
    display: block;
  }

  &.sm {
    width: 30%;
  }

  &.stretched {
    align-self: stretch;
  }

  &.margin-l {
    margin-top: 1.5rem;
  }

  @media only screen and (max-width: 1120px) {
    padding: 0.5rem;
  }

  @media only screen and (max-width: 900px) {
    &.sm {
      width: 100%;
      max-height: 15rem;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 370px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
