import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import OptionalForm from "../../components/OptionalForm";
import { api } from "../../services/api";
import { onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const UpdateOptionals = () => {
  const [optional, setOptional] = useState();

  const params = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const didcallAPI = useRef(false);

  useEffect(() => {
    if (!params || didcallAPI.current) return;

    const backToList = () => {
      navigate("/optionals");
    };

    const getOptional = async () => {
      await api
        .get(`/api/optionals/${params.id}`)
        .then(response => setOptional(response.data))
        .catch(e => {
          if (e.status === 404) {
            e.msg = "Adicional não encontrado!";
          }
          onRequestError(e.msg, e, backToList);
        })
        .finally(() => setLoading(false));
    };

    didcallAPI.current = true;
    setLoading(true);
    getOptional();
  }, [params, setLoading, navigate]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Editar Adicional</h1>
      <OptionalForm optional={optional} />
    </div>
  );
};

export default UpdateOptionals;
