import { styled } from "styled-components";

export const ImgPreviewCardWrapper2 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: var(--lead);
  color: var(--lighter);
  padding: 0.5rem;
  border-radius: 10px;
  font-size: 20px;
  width: fit-content;

  img {
    width: 220px;
    border-radius: 10px;
  }

  span,
  p {
    pointer-events: none;
  }

  & > div {
    align-self: start;

    & > button {
      display: block;
      color: var(--lighter);
      transition: all 350ms;

      &.download {
        margin-top: 1.25rem;
      }

      &:hover {
        &.del {
          color: var(--red);
        }

        &.download {
          color: var(--blue2);
        }
      }

      span {
        font-size: 25px;
      }
    }
  }

  @media only screen and (max-width: 1550px) {
    img {
      width: 150px;
    }
  }

  @media only screen and (max-width: 1200px) {
    img {
      width: 100px;
    }
  }

  @media only screen and (max-width: 1120px) {
    padding: 0.25rem 0.5rem;
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 800px) {
    font-size: 12px;
  }
`;
