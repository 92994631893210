import React, { useEffect, useRef, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import * as S from "../styles/guestPages";

import { useLoading } from "../contexts/LoadingContext";
import { useAuth } from "../contexts/AuthContext";
import Loading from "../components/Loading";

import { api } from "../services/api";

const GuestLayout = () => {
  const [checkedAuth, setCheckedAuth] = useState(false);

  const didCallApi = useRef(false);

  const { loading } = useLoading();
  const { user, setUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const getUser = async () => {
      await api
        .get("/api/user")
        .then(response => setUser(response.data))
        .catch(e => console.error(e))
        .finally(() => {
          setCheckedAuth(true);
          didCallApi.current = false;
        });
    };

    if (!user) {
      if (!didCallApi.current) {
        didCallApi.current = true;
        getUser();
      }
    } else {
      setCheckedAuth(true);
    }

    // eslint-disable-next-line
  }, [location]);

  if (!checkedAuth) {
    return <Loading />;
  }

  return !user ? (
    <>
      {loading && <Loading />}
      <S.PageWrapper className="flexbox v-centered h-centered">
        <S.CardWrapper>
          <S.LogoWrapper>
            <h1>GTA GUARITAS</h1>
            <h2>Painel de Controle</h2>
          </S.LogoWrapper>

          <Outlet />
        </S.CardWrapper>
      </S.PageWrapper>
    </>
  ) : (
    <Navigate to={"/home"} />
  );
};

export default GuestLayout;
