import ModelTypeForm from "../../components/ModelTypeForm";

const CreateModelTypes = () => {
  return (
    <div className="h-padded v-padded-lg">
      <h1>Cadastrar Modelo</h1>
      <ModelTypeForm />
    </div>
  );
};

export default CreateModelTypes;
