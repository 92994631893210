import { styled } from "styled-components";

export const CheckboxCardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: var(--lead);
  color: var(--lighter);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  width: fit-content;

  &.selected {
    background: var(--blue2);
  }

  input {
    margin-right: 0.5rem;
  }

  label {
    pointer-events: none;
    margin-bottom: 0;
  }

  img,
  video {
    width: 200px;
    height: 200px;
    border-radius: 10px;
  }

  div.overlay {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    span {
      display: block;
      color: var(--purple3);
      font-size: 30px;
      border: 3px solid var(--purple3);
      border-radius: 10px;
      padding: 0.5rem;
    }
  }

  @media only screen and (max-width: 1120px) {
    padding: 0.25rem 0.5rem;
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;

    img,
    video {
      width: 150px;
      height: 150px;
    }
  }

  @media only screen and (max-width: 800px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    img,
    video {
      width: 120px;
      height: 120px;
    }
  }

  @media only screen and (max-width: 500px) {
    img,
    video {
      width: 90px;
      height: 90px;
    }
  }
`;
