import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

import FormButtons from "../FormButtons";

import { api } from "../../services/api";
import {
  fireSuccessMsg,
  onRequestError,
  validationNeutral,
  validationSuccess,
  validationError,
  checkValidations,
} from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";
import { useAuth } from "../../contexts/AuthContext";

const UserForm = ({ userToEdit, isSelf = false }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [passOld, setPassOld] = useState("");
  const [role, setRole] = useState("user");

  const [passVisiblity, setPassVisibility] = useState(false);
  const [passConfirmVisiblity, setPassConfirmVisibility] = useState(false);
  const [passOldVisiblity, setPassOldVisibility] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { setLoading } = useLoading();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const validations = useRef({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    symbol: false,
    match: false,
  });

  useEffect(() => {
    if (userToEdit) {
      setName(userToEdit.name);
      setEmail(userToEdit.email);
      setRole(userToEdit.role);
    }
  }, [userToEdit]);

  useEffect(() => {
    if (userToEdit && !showPassword) {
      return;
    }

    if (!password) {
      validationNeutral("validate-length", validations);
      validationNeutral("validate-lowercase", validations);
      validationNeutral("validate-uppercase", validations);
      validationNeutral("validate-number", validations);
      validationNeutral("validate-symbol", validations);
      return;
    }

    password.length >= 8
      ? validationSuccess("validate-length", validations)
      : validationError("validate-length", validations);

    /[a-z]/.test(password)
      ? validationSuccess("validate-lowercase", validations)
      : validationError("validate-lowercase", validations);

    /[A-Z]/.test(password)
      ? validationSuccess("validate-uppercase", validations)
      : validationError("validate-uppercase", validations);

    /\d/.test(password)
      ? validationSuccess("validate-number", validations)
      : validationError("validate-number", validations);

    /[!@#$%^&*+=_~;:´`?'"<>{,/|}.[\]()-]/.test(password)
      ? validationSuccess("validate-symbol", validations)
      : validationError("validate-symbol", validations);
  }, [userToEdit, showPassword, password]);

  useEffect(() => {
    if (userToEdit && !showPassword) {
      return;
    }

    if (!password || !passConfirm) {
      validationNeutral("validate-match", validations);
    } else if (password === passConfirm) {
      validationSuccess("validate-match", validations);
    } else {
      validationError("validate-match", validations);
    }

    setValidated(checkValidations(validations));
  }, [userToEdit, showPassword, password, passConfirm]);

  const backToList = () => {
    navigate(!user || user.role === "admin" ? "/users" : "/home");
  };

  const resetSelfPassword = () => {
    if (showPassword) {
      setPassword("");
      setPassConfirm("");
      setPassOld("");
      setPassVisibility(false);
      setPassConfirmVisibility(false);
      setPassOldVisibility(false);
      setValidated(false);
    }

    setShowPassword(!showPassword);
  };

  const onSuccess = (msg, isEdit) => {
    let cb = null;
    if (isSelf) {
      cb = updateAuth;
    } else if (isEdit) {
      cb = backToList;
    }

    fireSuccessMsg(msg, cb);
    setName("");
    setEmail("");
    setPassword("");
    setPassConfirm("");
    setPassOld("");
    setRole("user");

    setPassVisibility(false);
    setPassConfirmVisibility(false);
    setPassOldVisibility(false);
    setValidated(false);
  };

  const onAuthSuccess = userData => {
    setUser(userData);
    backToList();
  };

  const createNewUser = async () => {
    await api
      .post("/api/users", {
        name: name,
        email: email,
        password: password,
        password_confirmation: passConfirm,
        role: role,
      })
      .then(response => onSuccess(response.data.msg))
      .catch(e => {
        if (e.status === 422) {
          e.msg = "Já existe um usuário com esse email!";
        } else if (!e.caught) {
          e.msg = "Algo deu errado ao cadastrar o usuário!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  const editUser = async () => {
    await api
      .patch(isSelf ? "/api/update-self" : `/api/users/${userToEdit.id}`, {
        name: name,
        email: email,
        current_password: passOld,
        password: password,
        password_confirmation: passConfirm,
        role: role,
      })
      .then(response => onSuccess(response.data.msg, true))
      .catch(e => {
        if (e.status === 422) {
          e.msg = "Senha atual está incorreta!";
        } else if (!e.caught) {
          e.msg = "Algo deu errado ao editar o usuário!";
        }
        onRequestError(e.msg, e);
        setLoading(false);
      });
  };

  const updateAuth = async () => {
    await api
      .get("/api/user")
      .then(response => onAuthSuccess(response.data))
      .catch(e => onRequestError(e.msg, e))
      .finally(() => setLoading(false));
  };

  const submitHandler = e => {
    e.preventDefault();
    setLoading(true);
    if (userToEdit) {
      editUser();
    } else {
      createNewUser();
    }
  };

  const sendResetEmail = e => {
    e.preventDefault();
    setLoading(true);

    api
      .post("/forgot-password", { email: email })
      .then(() => fireSuccessMsg("Email de redefinição enviado com sucesso!"))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao enviar o email de redefinição!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.UserFormWrapper onSubmit={submitHandler}>
      <div>
        <label htmlFor="inp_email">Email</label>
        <input
          id="inp_email"
          type="email"
          placeholder="Email do usuário"
          maxLength={255}
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={!!userToEdit}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_name">Nome</label>
        <input
          id="inp_name"
          type="text"
          placeholder="Nome completo do usuário"
          maxLength={255}
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_role">Tipo de usuário</label>
        <select
          id="inp_role"
          name="role"
          value={role}
          onChange={e => setRole(e.target.value)}
          disabled={user && user.role !== "admin"}
          required
        >
          <option value={"user"}>Usuário comum</option>
          <option value={"admin"}>Administrador</option>
        </select>
      </div>

      {userToEdit && (
        <S.ResetBtnWrapper>
          <button
            type="button"
            className="btn blue"
            onClick={isSelf ? resetSelfPassword : sendResetEmail}
          >
            <span className="material-symbols-rounded">
              {showPassword ? "cancel" : "lock_reset"}
            </span>
            <span className="btn-text">
              {showPassword ? "Cancelar redefinição" : "Redefinir senha"}
            </span>
          </button>
        </S.ResetBtnWrapper>
      )}

      {showPassword && (
        <div>
          <label htmlFor="inp_password_old">Senha atual</label>
          <div style={{ position: "relative" }}>
            <input
              id="inp_password_old"
              type={passOldVisiblity ? "text" : "password"}
              placeholder="Digite sua senha atual"
              value={passOld}
              onChange={e => setPassOld(e.target.value)}
              required
            />
            <span
              className="material-symbols-rounded eye-btn"
              onClick={() => setPassOldVisibility(!passOldVisiblity)}
            >
              {passOldVisiblity ? "visibility_off" : "visibility"}
            </span>
          </div>
        </div>
      )}

      {(!userToEdit || showPassword) && (
        <div>
          <label htmlFor="inp_password" className="m-sm">
            Nova senha
          </label>
          <div className="requirements">
            <p>A senha deve ter, no mínimo:</p>
            <p id="validate-length">
              <span className="material-symbols-rounded">
                fiber_manual_record
              </span>
              8 caracteres;
            </p>
            <p id="validate-lowercase">
              <span className="material-symbols-rounded">
                fiber_manual_record
              </span>
              uma letra minúscula;
            </p>
            <p id="validate-uppercase">
              <span className="material-symbols-rounded">
                fiber_manual_record
              </span>
              uma letra maiúscula;
            </p>
            <p id="validate-number">
              <span className="material-symbols-rounded">
                fiber_manual_record
              </span>
              um número;
            </p>
            <p id="validate-symbol">
              <span className="material-symbols-rounded">
                fiber_manual_record
              </span>
              um caractere especial
            </p>
          </div>
          <div style={{ position: "relative" }}>
            <input
              id="inp_password"
              type={passVisiblity ? "text" : "password"}
              placeholder="Cadastre uma nova senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required={!userToEdit || showPassword}
            />
            <span
              className="material-symbols-rounded eye-btn"
              onClick={() => setPassVisibility(!passVisiblity)}
            >
              {passVisiblity ? "visibility_off" : "visibility"}
            </span>
          </div>
        </div>
      )}

      {(!userToEdit || showPassword) && (
        <div>
          <label htmlFor="inp_password_confirmation" className="m-sm">
            Confirmação de senha
          </label>
          <div className="requirements">
            <p id="validate-match">
              <span className="material-symbols-rounded">
                fiber_manual_record
              </span>
              As senhas devem ser iguais
            </p>
          </div>
          <div style={{ position: "relative" }}>
            <input
              id="inp_password_confirmation"
              type={passConfirmVisiblity ? "text" : "password"}
              placeholder="Confime sua senha"
              value={passConfirm}
              onChange={e => setPassConfirm(e.target.value)}
              required={!userToEdit || showPassword}
            />
            <span
              className="material-symbols-rounded eye-btn"
              onClick={() => setPassConfirmVisibility(!passConfirmVisiblity)}
            >
              {passConfirmVisiblity ? "visibility_off" : "visibility"}
            </span>
          </div>
        </div>
      )}

      <FormButtons
        cancelCallback={backToList}
        disableCondition={
          (!userToEdit && !validated) || (showPassword && !validated)
        }
      />
    </S.UserFormWrapper>
  );
};

export default UserForm;
