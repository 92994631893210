import React, { createContext, useContext, useState } from "react";

const initialState = {
  user: null,
};

export const AuthContext = createContext(initialState);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(initialState.user);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
