import React, { useEffect, useRef, useState } from "react";

import CarouselSlideForm from "../../components/CarouselSlideForm";

import { api } from "../../services/api";
import { onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const CreateCarouselSlides = () => {
  const [products, setProducts] = useState(null);
  const [allSlides, setAllSlides] = useState(null);

  const { setLoading } = useLoading();

  const didcallAPI = useRef(false);

  useEffect(() => {
    if (didcallAPI.current) return;

    const getApiResources = async () => {
      const apiCalls = [
        api.get("/api/products"),
        api.get("/api/carousel-slides"),
      ];
      await Promise.all(apiCalls)
        .then(responses => {
          setProducts(responses[0].data);
          setAllSlides(responses[1].data);
        })
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    };

    didcallAPI.current = true;
    setLoading(true);
    getApiResources();
  }, [setLoading]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Cadastrar Slide</h1>
      <CarouselSlideForm allSlides={allSlides} products={products} />
    </div>
  );
};

export default CreateCarouselSlides;
