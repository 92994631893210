import { styled } from "styled-components";

export const NavbarWrapper = styled.div`
  background: var(--lighter);
  position: fixed;
  max-width: 20vw;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  &.screen-s {
    display: none;
    max-width: 100vw;
    bottom: 0;
    top: auto;
    height: auto;
  }

  @media only screen and (max-width: 750px) {
    &.screen-l {
      display: none;
    }
    &.screen-s {
      // display: block;
      // NOTA: DESABILITADO PQ ACABOU FICANDO COM ICONES DEMAIS
    }
  }
`;

export const NavbarContent = styled.div`
  padding: 2.25rem 2rem;
  height: 100%;

  button,
  a.link-mobile {
    display: flex;
    align-items: center;
    border-radius: 15px;
    font-size: 16px;
    transition: all 450ms;
  }

  a.link-mobile {
    display: block;
    text-align: center;
    color: var(--dark);

    & > span {
      display: block;
      margin: 0 0 0.25rem 0 !important;
    }

    &.active {
      color: var(--yellow);
    }
  }

  div.btn-container:not(:last-of-type),
  div.title {
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-height: 750px) {
    div.btn-container:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  }

  @media only screen and (max-height: 680px) {
    div.title {
      margin-bottom: 1.5rem;
    }
  }

  @media only screen and (max-height: 680px) {
    div.btn-container:not(:last-of-type),
    div.title {
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 1120px) {
    padding: 2.25rem 0.75rem;
  }

  @media only screen and (max-width: 900px) {
    button {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 820px) {
    padding: 2.25rem 0.5rem;
  }

  @media only screen and (max-width: 750px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    div.btn-container:not(:last-of-type) {
      margin: 0 1rem 0 0 !important;
    }
  }

  @media only screen and (max-width: 400px) {
    padding: 1rem 1rem;

    a.link-mobile {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 320px) {
    div.btn-container:not(:last-of-type) {
      margin: 0 0.5rem 0 0 !important;
    }
  }

  @media only screen and (max-width: 300px) {
    padding: 1rem 0.5rem;

    a.link-mobile {
      font-size: 10px;
    }
  }
`;

export const LinksContainer = styled.div`
  display: none;
  background: ${props => props.color};
  margin-top: 0.5rem;
  padding: 0.25rem 1rem 0.25rem 3.5rem;
  border-radius: 15px;

  &.active {
    display: block;
  }

  a,
  button {
    display: block;
    font-size: 14px;
    font-family: "Be Vietnam Pro", sans-serif;
    color: var(--lead);
    padding: 0;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &:hover,
    &.selected {
      color: var(--dark);
      text-decoration: underline;
    }

    &.selected {
      pointer-events: none;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 0.25rem 1rem 0.25rem 2.75rem;
  }

  @media only screen and (max-width: 820px) {
    padding: 0.25rem 1rem 0.25rem 2.25rem;
  }
`;

export const NavBtn = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  color: var(--lead);

  &:hover,
  &.selected {
    background: ${props => props.color};
    color: var(--dark);
  }

  &.selected {
    span:not(:first-of-type) {
      transform: rotate(180deg);
    }
  }

  span {
    &:first-of-type {
      margin-right: 1rem;
    }

    &:not(:first-of-type) {
      transition: all 250ms;
    }
  }

  @media only screen and (max-width: 900px) {
    span:first-of-type {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 820px) {
    padding: 0.5rem;
  }

  @media only screen and (max-width: 820px) {
    padding: 0.5rem;
  }
`;

export const LogoutBtn = styled.button`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  justify-content: center;
  width: calc(100% - 4rem);
  padding: 1rem;
  border: 2px solid var(--orange);
  color: var(--orange);

  &:hover {
    background: var(--orange);
    color: var(--lighter);
  }

  span {
    margin-right: 0.5rem;
  }

  @media only screen and (max-height: 680px) {
    bottom: 1rem;
  }

  @media only screen and (max-width: 1120px) {
    padding: 0.5rem;
    width: calc(100% - 1.5rem);
    left: 0.75rem;
  }

  @media only screen and (max-width: 820px) {
    span {
      margin: 0 !important;
    }
  }
`;
