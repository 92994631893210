import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ProductForm from "../../components/ProductForm";

import { api } from "../../services/api";
import { onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const UpdateProducts = () => {
  const [product, setProduct] = useState();
  const [modelTypes, setModelTypes] = useState(null);
  const [optionals, setOptionals] = useState(null);

  const params = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const didcallAPI = useRef(false);

  useEffect(() => {
    if (!params || didcallAPI.current) return;

    const backToList = () => {
      navigate("/products");
    };

    const getApiResources = () => {
      const apiCalls = [
        api.get(`/api/products/${params.id}`),
        api.get("/api/model-types"),
        api.get("/api/optionals"),
      ];
      Promise.all(apiCalls)
        .then(responses => {
          setProduct(responses[0].data);
          setModelTypes(responses[1].data);
          setOptionals(responses[2].data);
        })
        .catch(e => {
          if (e.status === 404) {
            e.msg = "Produto não encontrado!";
          }
          onRequestError(e.msg, e, backToList);
        })
        .finally(() => setLoading(false));
    };

    didcallAPI.current = true;
    setLoading(true);
    getApiResources();

    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Editar Produto</h1>
      <ProductForm
        product={product}
        productHandler={setProduct}
        modelTypes={modelTypes}
        optionals={optionals}
      />
    </div>
  );
};

export default UpdateProducts;
