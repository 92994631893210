import { useEffect, useState } from "react";
import * as S from "./styles";

import StyledCheckbox from "../StyledCheckbox";

const CheckboxCard = ({
  data,
  idArrayState,
  idArrayHandler,
  dataArrayState,
  dataArrayHandler,
  contentPath,
}) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!data.id) return;

    let isSelected;
    if (idArrayState) {
      isSelected = idArrayState.includes(data.id);
    } else if (dataArrayState) {
      isSelected =
        dataArrayState.findIndex(checkData => checkData.id === data.id) !== -1;
    }

    setSelected(isSelected);
  }, [data, idArrayState, dataArrayState]);

  const addToIdArray = id => {
    const arrayCopy = [...idArrayState, id];
    idArrayHandler(arrayCopy);
  };

  const removeFromIdArray = id => {
    let arrayCopy = [...idArrayState];
    arrayCopy = arrayCopy.filter(checkId => checkId !== id);
    idArrayHandler(arrayCopy);
  };

  const addToDataArray = data => {
    const arrayCopy = [...dataArrayState, data];
    dataArrayHandler(arrayCopy);
  };

  const removeFromDataArray = data => {
    let arrayCopy = [...dataArrayState];
    arrayCopy = arrayCopy.filter(checkData => checkData.id !== data.id);
    dataArrayHandler(arrayCopy);
  };

  const changeHandler = e => {
    const el = e.target;
    const check = el.checked;

    if (check) {
      if (idArrayState) {
        addToIdArray(data.id);
      } else if (dataArrayState) {
        addToDataArray(data);
      }
    } else {
      if (idArrayState) {
        removeFromIdArray(data.id);
      } else if (dataArrayState) {
        removeFromDataArray(data);
      }
    }
  };

  const clickHandler = e => {
    const el = e.target;
    if (el.tagName === "DIV") {
      el.children[0].click();
    }
  };

  return (
    <S.CheckboxCardWrapper
      className={selected ? "selected" : ""}
      onClick={clickHandler}
    >
      <StyledCheckbox
        id={`inp_${data.name}_${data.id}`}
        state={data}
        handler={changeHandler}
        checkCondition={selected}
      />
      <label htmlFor={`inp_${data.name}_${data.id}`}>
        {contentPath ? (
          contentPath.includes("video") ? (
            <>
              <video src={contentPath}></video>
              <div className="overlay">
                <span className="material-symbols-rounded">videocam</span>
              </div>
            </>
          ) : (
            <img src={contentPath} alt="" />
          )
        ) : (
          data.name
        )}
      </label>
    </S.CheckboxCardWrapper>
  );
};

export default CheckboxCard;
