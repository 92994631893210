import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ModelTypeForm from "../../components/ModelTypeForm";
import { api } from "../../services/api";
import { onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const UpdateModelTypes = () => {
  const [modelType, setModelType] = useState();

  const params = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const didcallAPI = useRef(false);

  useEffect(() => {
    if (!params || didcallAPI.current) return;

    const backToList = () => {
      navigate("/model-types");
    };

    const getModelType = async () => {
      await api
        .get(`/api/model-types/${params.id}`)
        .then(response => setModelType(response.data))
        .catch(e => {
          if (e.status === 404) {
            e.msg = "Modelo não encontrado!";
          }
          onRequestError(e.msg, e, backToList);
        })
        .finally(() => setLoading(false));
    };

    didcallAPI.current = true;
    setLoading(true);
    getModelType();
  }, [params, setLoading, navigate]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Editar Modelo</h1>
      <ModelTypeForm modelType={modelType} />
    </div>
  );
};

export default UpdateModelTypes;
