import { styled } from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  max-width: 70%;
  width: 100%;
  background: var(--lighter);
  border-radius: 0 0 0 15px;
  padding: 1rem 1rem 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  nav a {
    color: var(--lighter);
    text-decoration: underline;
    font-size: 18px;

    &:not(:last-of-type) {
      margin-right: 1.5rem;
    }
  }

  @media only screen and (max-width: 1000px) {
    max-width: 78%;
    padding: 1rem 1rem 1rem 2rem;
  }

  @media only screen and (max-width: 800px) {
    div.title {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 750px) {
    max-width: 100%;
    padding: 1rem 1.5rem;
  }

  @media only screen and (max-width: 650px) {
    div.title {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 580px) {
    div {
      &.title {
        display: none;
      }

      &.user-tools {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 300px) {
    padding: 1rem;
  }
`;

export const ClockWrapper = styled.div`
  background: var(--ice);
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  color: var(--lead);
  text-transform: capitalize;
  font-size: 14px;
  margin-right: 3rem;

  span:first-of-type {
    margin-right: 0.5rem;
  }

  @media only screen and (max-width: 900px) {
    padding: 0.5rem 0.75rem;
    margin-right: 1.5rem;
  }

  @media only screen and (max-width: 850px) {
    margin-right: 1rem;
    font-size: 12px;
  }

  @media only screen and (max-width: 580px) {
    display: none;
  }
`;

export const UserWrapper = styled.div`
  color: var(--dark);
  cursor: pointer;
  position: relative;

  div {
    &.container {
      background: var(--lighter);

      & > *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    &.avatar {
      background: var(--yellow2);
      padding: 0.75rem;
      border-radius: 50%;
      font-size: 30px;
      text-transform: uppercase;
    }
  }

  p {
    &:first-of-type {
      font-size: 14px;
      font-weight: 600;
    }

    &:not(:first-of-type) {
      font-size: 12px;
      color: var(--lead);
    }
  }

  span[id^="profile-arrow"] {
    transition: all 250ms;

    &.active {
      transform: rotate(180deg);
    }
  }

  span#profile-arrow-mobile {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    div.avatar {
      font-size: 20px;
      padding: 0.5rem;
    }
  }

  @media only screen and (max-width: 580px) {
    width: 100%;

    span#profile-arrow {
      display: none;
    }

    span#profile-arrow-mobile {
      display: block;
    }

    div.container {
      justify-content: space-between;

      & > * {
        margin: 0 !important;
      }
    }
  }
`;

export const UserDropdown = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  padding: 1rem;
  border-radius: 0 0 10px 10px;
  background: var(--lighter);
  font-size: 14px;
  z-index: -1;
  pointer-events: none;
  transition: all 200ms;

  &.active {
    top: 100%;
    opacity: 1;
    pointer-events: auto;
  }

  & > * {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0;
    color: var(--lead);
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    span {
      margin-right: 0.5rem;
      font-size: 20px;
    }

    &:hover {
      color: var(--yellow2);
    }
  }

  @media only screen and (max-width: 580px) {
    padding: 1.5rem 1rem;

    & > * {
      font-size: 16px;
  
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
  }
`;
