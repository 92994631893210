import React, { createContext, useContext, useState } from "react";

const initialState = {
  showMediaLib: false,
};

export const MediaLibContext = createContext(initialState);

export function MediaLibProvider({ children }) {
  const [showMediaLib, setShowMediaLib] = useState(initialState.showMediaLib);

  return (
    <MediaLibContext.Provider
      value={{
        showMediaLib,
        setShowMediaLib,
      }}
    >
      {children}
    </MediaLibContext.Provider>
  );
}

export function useMediaLib() {
  return useContext(MediaLibContext);
}
