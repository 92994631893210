import * as S from "../styles/guestPages";
import { Link } from "react-router-dom";

const AccountReady = () => {
  return (
    <S.PageWrapper className="flexbox v-centered h-centered">
      <S.CardWrapper>
        <S.LogoWrapper>
          <h1>GTA GUARITAS</h1>
          <h2>Painel de Controle</h2>
        </S.LogoWrapper>

        <S.FormWrapper>
          <div>
            <h3 style={{ marginBottom: "0.5rem" }}>
              Sua conta está confirmada!
            </h3>
            <h4>Faça login para entrar em sua conta.</h4>
          </div>

          <Link to={"/"} className="btn blue lg full">
            Login
          </Link>
        </S.FormWrapper>
      </S.CardWrapper>
    </S.PageWrapper>
  );
};

export default AccountReady;
