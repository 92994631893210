import { styled } from "styled-components";

export const HomePageWrapper = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (max-width: 300px) {
    gap: 1rem;
  }
`;

export const HomePageSectionWrapper = styled.div`
  background: ${props => props.color};
  color: var(--dark);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  cursor: pointer;

  p {
    margin-top: 1rem;
  }

  &:hover {
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4));
  }
`;
