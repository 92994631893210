import { styled } from "styled-components";

export const ImgPreviewCardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lead);
  padding: 0.25rem;
  border-radius: 10px;
  width: fit-content;
  cursor: pointer;
  min-width: 228px;
  min-height: 100px;
  max-width: 248px;
  max-height: 228px;

  &.selected {
    background: var(--blue2);
  }

  img,
  video {
    width: 220px;
    max-height: 220px;
    border-radius: 10px;
  }

  p {
    color: var(--purple3);
    text-align: center;

    span {
      font-size: 42px;
    }
  }

  div.alert {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border: 1px solid var(--dark);
    border-radius: 50%;

    span {
      color: var(--red);
      font-size: 32px;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: var(--dark);
      border: 2px solid var(--red);
      border-radius: 50%;
      padding: 0.25rem;
    }
  }

  @media only screen and (max-width: 1550px) {
    min-width: 158px;
    max-width: 178px;
    max-height: 158px;

    img,
    video {
      width: 150px;
      max-height: 150px;
    }
  }

  @media only screen and (max-width: 1200px) {
    min-width: 108px;
    max-width: 128px;
    max-height: 108px;

    img,
    video {
      width: 100px;
      max-height: 100px;
    }
  }
`;
