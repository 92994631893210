import React, { useEffect, useRef, useState } from "react";

import ProductForm from "../../components/ProductForm";

import { api } from "../../services/api";
import { onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const CreateProducts = () => {
  const [modelTypes, setModelTypes] = useState(null);
  const [optionals, setOptionals] = useState(null);

  const { setLoading } = useLoading();

  const didcallAPI = useRef(false);

  useEffect(() => {
    if (didcallAPI.current) return;

    const getApiResources = async () => {
      const apiCalls = [api.get("/api/model-types"), api.get("/api/optionals")];
      await Promise.all(apiCalls)
        .then(responses => {
          setModelTypes(responses[0].data);
          setOptionals(responses[1].data);
        })
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    };

    didcallAPI.current = true;
    setLoading(true);
    getApiResources();
  }, [setLoading]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Cadastrar Produto</h1>
      <ProductForm modelTypes={modelTypes} optionals={optionals} />
    </div>
  );
};

export default CreateProducts;
