import { styled } from "styled-components";

export const FormButtonsWrapper = styled.div`
  margin-top: 3rem;
  display: flex;

  button {
    padding: 0.5rem 3rem;
    border-radius: 5px;

    &.confirm {
      margin-right: 2rem;
    }
  }

  @media only screen and (max-width: 520px) {
    margin-top: 1.5rem;
    display: block;

    button {
      width: 100%;

      &.confirm {
        margin: 0 0 1.5rem 0;
      }
    }
  }

  @media only screen and (max-width: 520px) {
    margin-top: 1rem;

    button.confirm {
        margin: 0 0 1rem 0;
      }
    }
  }
`;
