import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import GlobalStyle from "./styles/global";
import MainRouter from "./Routes";
import { MediaLibProvider } from "./contexts/MediaLibContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import { AuthProvider } from "./contexts/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <MediaLibProvider>
      <LoadingProvider>
        <AuthProvider>
          <RouterProvider router={MainRouter} />
        </AuthProvider>
      </LoadingProvider>
    </MediaLibProvider>
  </React.StrictMode>
);
