import Swal from "sweetalert2";

export const fireSuccessMsg = (msg, callback) => {
  Swal.fire({
    text: msg,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 3000,
  }).then(() => {
    if (callback) {
      callback();
    }
  });
};

export const fireErrorMsg = (msg, callback) => {
  Swal.fire({
    text: msg,
    icon: "error",
    title: "Oops...",
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 3000,
  }).then(() => {
    if (callback) {
      callback();
    }
  });
};

export const fireDeleteMsg = (itemsToDelete, callback) => {
  Swal.fire({
    icon: "warning",
    title: "Você tem certeza de que quer excluir os itens a seguir?",
    text: itemsToDelete,
    showDenyButton: true,
    confirmButtonText: "Sim, excluir",
    denyButtonText: `Cancelar`,
  }).then(result => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const onRequestError = (msg, error, callback) => {
  fireErrorMsg(msg, callback);
  console.error(error);
};

export const validationNeutral = (id, ref) => {
  const el = document.getElementById(id);
  if (!el?.classList.contains("neutral")) {
    el.classList.remove("success", "error");
    el.classList.add("neutral");
    el.children[0].innerHTML = "fiber_manual_record";

    const param = getValidationParam(id);
    ref.current[param] = false;
  }
};

export const validationSuccess = (id, ref) => {
  const el = document.getElementById(id);
  if (!el?.classList.contains("success")) {
    el.classList.remove("neutral", "error");
    el.classList.add("success");
    el.children[0].innerHTML = "check";

    const param = getValidationParam(id);
    ref.current[param] = true;
  }
};

export const validationError = (id, ref) => {
  const el = document.getElementById(id);
  if (!el?.classList.contains("error")) {
    el.classList.remove("success", "neutral");
    el.classList.add("error");
    el.children[0].innerHTML = "close";

    const param = getValidationParam(id);
    ref.current[param] = false;
  }
};

export const checkValidations = ref => {
  return Object.values(ref.current).every(val => val === true);
};

const getValidationParam = id => {
  return id.split("-")[1];
};

export const getDateString = () => {
  const now = new Date();
  return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
};

export const getDateFromIsoString = dateStr => {
  const date = dateStr.split("T")[0];
  const parts = date.split("-");

  return `${parts[2]}/${parts[1]}/${parts[0]}`;
};

export const getFileNameFromMime = mimeType => {
  const parts = mimeType.split("/");
  return `${parts[0]}-${getDateString()}.${parts[1]}`;
};
