import { styled } from "styled-components";

export const FiltersWrapper = styled.div`
  display: flex;
  color: var(--lead);

  label {
    font-size: 10px;
    margin-bottom: 0.25rem;
  }

  select {
    width: auto;
    font-size: 12px;
    color: var(--lead);
    padding: 0.25rem;
  }

  div.selectors > div:first-of-type {
    margin: 0 2rem 0 0;
  }

  @media only screen and (max-width: 1170px) {
    div.selectors > div:first-of-type {
      margin: 0 0.5rem 0 0;
    }
  }

  @media only screen and (max-width: 1050px) {
    select {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 900px) {
    display: block;

    div.selectors > div:first-of-type {
      margin: 0 2rem 0 0;
    }
  }

  @media only screen and (max-width: 450px) {
    div.selectors {
      justify-content: space-between;

      & > div:first-of-type {
        margin: 0 0.5rem 0 0;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    div.selectors {
      display: block;

      & > div:first-of-type {
        margin: 0 0 1rem 0;
      }
    }

    select {
      width: 100%;
    }
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: end;
  margin: 1rem 0 0 auto;
  border: 1px solid var(--lead);
  border-radius: 5px;
  font-size: 12px;

  & > * {
    height: 100%;
  }

  span {
    &.counter-total {
      display: block;
      padding: 0.25rem 0.5rem;
      border-right: 1px solid var(--lead);
    }

    &.counter-exhibits {
      font-weight: bold;
    }

    &[class^="material-symbols"] {
      display: block;
      font-size: 18px;
    }
  }

  button {
    padding: 0.25rem 0.35rem;
    height: 100%;
    font-size: 12px;
    color: var(--lead);

    &:disabled {
      color: var(--purple);
      pointer-events: none;
    }

    &:hover {
      color: var(--dark);
    }

    &.active {
      color: var(--yellow);
    }
  }

  @media only screen and (max-width: 1050px) {
    font-size: 10px;

    button {
      font-size: 10px;
    }

    span[class^="material-symbols"] {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 900px) {
    width: fit-content;
    margin: 2rem 0 0;
  }

  @media only screen and (max-width: 500px) {
    display: block;
    width: 100%;

    span.counter-total {
      padding: 0.5rem;
      border-bottom: 1px solid var(--lead);
      border-right: none;
      text-align: center;
      width: 100%;
    }
  }

  @media only screen and (max-width: 400px) {
    button {
      padding: 0.25rem 0;
    }
  }
`;
