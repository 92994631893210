import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import UserForm from "../../components/UserForm";

import { api } from "../../services/api";
import { onRequestError } from "../../services/functions";

import { useLoading } from "../../contexts/LoadingContext";
import { useAuth } from "../../contexts/AuthContext";

const UpdateUsers = () => {
  const [editUser, setEditUser] = useState();
  const [isUserSelf, setIsUserSelf] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { user } = useAuth();

  const didcallAPI = useRef(false);

  useEffect(() => {
    if (!params || !user?.id || didcallAPI.current) return;

    const backToList = () => {
      navigate("/users");
    };

    const getUser = async () => {
      await api
        .get(`/api/users/${params.id}`)
        .then(response => setEditUser(response.data))
        .catch(e => {
          if (e.status === 404) {
            e.msg = "Usuário não encontrado!";
          }
          onRequestError(e.msg, e, backToList);
        })
        .finally(() => setLoading(false));
    };

    didcallAPI.current = true;
    if (params.id === user.id) {
      setIsUserSelf(true);
      setEditUser(user);
    } else {
      setLoading(true);
      getUser();
    }
  }, [params, user, setLoading, navigate]);

  return (
    <div className="h-padded v-padded-lg">
      <h1>Editar Usuário</h1>
      <UserForm userToEdit={editUser} isSelf={isUserSelf} />
    </div>
  );
};

export default UpdateUsers;
