import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import * as S from "../styles/guestPages";

import Loading from "../components/Loading";

import { useLoading } from "../contexts/LoadingContext";

import { api } from "../services/api";

const ConfirmationLayout = () => {
  const [checkedAuth, setCheckedAuth] = useState(false);

  const didCallApi = useRef(false);

  const { loading } = useLoading();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkedAuth) return;

    const getUser = async () => {
      await api
        .get("/api/user")
        .catch(e => {
          if (e.status === 401) {
            navigate("/");
          }
          console.error(e);
        })
        .finally(() => {
          setCheckedAuth(true);
          didCallApi.current = false;
        });
    };

    didCallApi.current = true;
    getUser();

    // eslint-disable-next-line
  }, [location]);

  if (!checkedAuth) {
    return <Loading />;
  }

  return (
    <>
      {loading && <Loading />}
      <S.PageWrapper className="flexbox v-centered h-centered">
        <S.CardWrapper>
          <S.LogoWrapper>
            <h1>GTA GUARITAS</h1>
            <h2>Painel de Controle</h2>
          </S.LogoWrapper>

          <Outlet />
        </S.CardWrapper>
      </S.PageWrapper>
    </>
  );
};

export default ConfirmationLayout;
