import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import ItemCard from "../../components/ItemCard";
import Filters from "../../components/Filters";
import StyledCheckbox from "../../components/StyledCheckbox";

import { api } from "../../services/api";
import {
  fireDeleteMsg,
  fireSuccessMsg,
  getDateString,
  onRequestError,
} from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

import fileDownload from "js-file-download";

const ListOptionals = () => {
  const [optionals, setOptionals] = useState([]);
  const [selected, setSelected] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [csvFile, setCsvFile] = useState(null);

  const { setLoading } = useLoading();
  const location = useLocation();

  const locationRef = useRef();

  const routeName = "optionals";

  useEffect(() => {
    if (location && location !== locationRef.current) {
      locationRef.current = location;
      setLoading(true);
      getOptionals();
    }

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!pagination) return;
    setOptionals(pagination.data);
  }, [pagination]);

  useEffect(() => {
    if (!csvFile) return;

    const importCsv = async () => {
      api
        .post(
          "/api/import-optionals",
          { file: csvFile },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then(response => fireSuccessMsg(response.data.msg, getOptionals))
        .catch(e => {
          if (!e.caught) {
            e.msg = "Não foi possível importar o arquivo .csv!";
          }
          onRequestError(e.msg, e);
          setLoading(false);
        })
        .finally(() => {
          setCsvFile(null);
          const el = document.getElementById("inp_csv_file");
          if (el) {
            el.value = null;
          }
        });
    };

    setLoading(true);
    importCsv();

    // eslint-disable-next-line
  }, [csvFile]);

  const getOptionals = async () => {
    await api
      .get(`/api/${routeName}${location.search}`)
      .then(response => {
        if (response.data.data) {
          setPagination(response.data);
        } else {
          setOptionals(response.data);
          setPagination(null);
        }
      })
      .catch(e => {
        if (e.status === 404) {
          e.msg = "Não foi possível listar os adicionais!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMassOptionalNames = () => {
    let names = "";

    selected.forEach((item, i) => {
      names += item.name;
      if (i < selected.length - 1) {
        names += ", ";
      }
    });

    return names;
  };

  const onDeleteSuccess = (msg, deletedItems) => {
    fireSuccessMsg(msg);

    const optionalsCopy = [...optionals];
    deletedItems.forEach(item => {
      const index = optionalsCopy.indexOf(item);
      optionalsCopy.splice(index, 1);
    });
    setOptionals(optionalsCopy);
    setSelected([]);
    const inputs = document.querySelectorAll("input[id^=inp_item_]:checked");
    inputs.forEach(inp => (inp.checked = false));
  };

  const deleteSingleOptional = optional => {
    fireDeleteMsg(optional.name, async () => {
      setLoading(true);

      await api
        .delete(`/api/${routeName}/${optional.id}`)
        .then(response => onDeleteSuccess(response.data.msg, [optional]))
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    });
  };

  const massDelete = () => {
    fireDeleteMsg(getMassOptionalNames(), async () => {
      setLoading(true);

      await api
        .post(`/api/${routeName}/mass-delete`, {
          ids: selected.map(item => item.id),
        })
        .then(response => onDeleteSuccess(response.data.msg, selected))
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    });
  };

  const onSelectAll = e => {
    const isChecked = e.target.checked;
    const inputs = document.querySelectorAll("[id^=inp_item_]");

    let allSelected = [];
    inputs.forEach((inp, i) => {
      inp.checked = isChecked;
      if (inp.checked) {
        allSelected = [...allSelected, optionals[i]];
      }
    });
    setSelected(allSelected);
  };

  const onBlobReceived = blob => {
    const fileName = `adicionais-${getDateString()}.csv`;
    fileDownload(blob, fileName);
  };

  const exportCsv = async () => {
    setLoading(true);
    await api
      .get(`/api/export-optionals`, { responseType: "blob" })
      .then(response => onBlobReceived(response.data))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Não foi possível gerar o arquivo .csv!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="h-padded v-padded-lg">
      <h1>Adicionais</h1>

      <Filters pagination={pagination} />

      <div
        className="flexbox v-centered h-centered controls"
        style={{ margin: "3rem auto" }}
      >
        <Link to={`/${routeName}/register`} className="btn blue add">
          <span className="material-symbols-rounded">add</span>
          <span className="btn-text">Cadastrar novo adicional</span>
        </Link>

        <button
          className="btn blue btn-control"
          onClick={e => e.target.querySelector("input").click()}
        >
          <input
            id="inp_csv_file"
            type="file"
            hidden
            onChange={e => setCsvFile(e.target.files[0])}
            onClick={e => e.stopPropagation()}
          />
          <span className="material-symbols-rounded">upload_file</span>
          <span className="btn-text">Importar CSV</span>
        </button>

        {optionals.length > 0 && (
          <button className="btn blue btn-control" onClick={exportCsv}>
            <span className="material-symbols-rounded">file_save</span>
            <span className="btn-text">Exportar CSV</span>
          </button>
        )}

        {selected.length > 0 && (
          <button className="btn red btn-control" onClick={massDelete}>
            <span className="material-symbols-rounded">delete</span>
            <span className="btn-text">Excluir selecionados</span>
          </button>
        )}
      </div>

      {optionals.length > 0 && (
        <div className="flexbox" style={{ marginBottom: "1rem" }}>
          <StyledCheckbox
            id="inp_select_all"
            handler={onSelectAll}
            style={{ margin: "0 1rem" }}
          />
          <label htmlFor="inp_select_all" style={{ marginBottom: 0 }}>
            Selecionar todos
          </label>
        </div>
      )}

      <div>
        {optionals.length === 0 && (
          <span
            style={{ display: "block", marginTop: "1rem", fontSize: "22px" }}
          >
            Nenhum adicional encontrado
          </span>
        )}
        {optionals.map((item, i) => (
          <ItemCard
            item={item}
            index={i}
            routeName={routeName}
            deleteHandler={deleteSingleOptional}
            selectionHandler={setSelected}
            selectionState={selected}
            warnings={[
              {
                label: "Não utilizado!",
                condition: item.products.length === 0,
              },
            ]}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default ListOptionals;
