import { useSearchParams } from "react-router-dom";

import * as S from "./styles";

const Filters = ({ pagination, hasHighlightProperty }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const keys = {
    orderBy: "orderBy",
    page: "page",
    perPage: "perPage",
  };

  const changeQueryString = paramPairs => {
    setSearchParams(params => {
      paramPairs.forEach(pair => params.set(pair[0], pair[1]));
      return params;
    });
  };

  const getIndexFromUrl = url => {
    return url?.split("page=")[1];
  };

  return (
    <S.FiltersWrapper>
      <div className="flexbox v-centered selectors">
        <div>
          <label htmlFor="inp_per_page">Exibir</label>
          <select
            id="inp_per_page"
            name="per_page"
            value={searchParams.get(keys.perPage) || ""}
            onChange={e =>
              changeQueryString([
                [keys.perPage, e.target.value],
                [keys.page, 1],
              ])
            }
          >
            <option value={""}>Todos</option>
            <option value={10}>10 por página</option>
            <option value={25}>25 por página</option>
            <option value={50}>50 por página</option>
            <option value={100}>100 por página</option>
          </select>
        </div>

        <div>
          <label htmlFor="inp_order">Ordenar por</label>
          <select
            id="inp_order"
            name="order"
            value={searchParams.get(keys.orderBy) || ""}
            onChange={e => changeQueryString([[keys.orderBy, e.target.value]])}
          >
            <option value={""}>Nenhum</option>
            {hasHighlightProperty && (
              <option value={"highlight,desc"}>Destaques</option>
            )}
            <option value={"name,asc"}>Nome A-Z</option>
            <option value={"name,desc"}>Nome Z-A</option>
            <option value={"created_at,desc"}>
              Data de criação, da mais nova
            </option>
            <option value={"created_at,asc"}>
              Data de criação, da mais antiga
            </option>
          </select>
        </div>
      </div>

      {pagination && (
        <S.NavigationWrapper>
          <span className="counter-total">
            <span className="counter-exhibits">
              {pagination.from}-{pagination.to}
            </span>
            {` de`} {pagination.total}
          </span>

          <div className="flexbox v-centered h-spaced-between">
            {pagination.links.map((item, i) => {
              let label;
              if (i === 0) {
                label = (
                  <span className="material-symbols-rounded">chevron_left</span>
                );
              } else if (i === pagination.links.length - 1) {
                label = (
                  <span className="material-symbols-rounded">
                    chevron_right
                  </span>
                );
              } else {
                label = item.label;
              }
              const index = getIndexFromUrl(item.url);
              return (
                <button
                  key={i}
                  onClick={() => changeQueryString([[keys.page, index]])}
                  className={item.active ? "active" : ""}
                  disabled={item.active || !index}
                >
                  {label}
                </button>
              );
            })}
          </div>
        </S.NavigationWrapper>
      )}
    </S.FiltersWrapper>
  );
};

export default Filters;
