import * as S from "./styles";

const FormButtons = ({
  confirmCallback,
  cancelCallback,
  disableCondition = false,
}) => {
  return (
    <S.FormButtonsWrapper>
      <button
        type="submit"
        className="btn confirm"
        onClick={confirmCallback}
        disabled={disableCondition}
      >
        <span className="material-symbols-rounded">check</span>
        <span className="btn-text">Confirmar</span>
      </button>

      <button type="button" className="btn cancel" onClick={cancelCallback}>
        <span className="material-symbols-rounded">close</span>
        <span className="btn-text">Cancelar</span>
      </button>
    </S.FormButtonsWrapper>
  );
};

export default FormButtons;
