import UserForm from "../../components/UserForm";

const CreateUsers = () => {
  return (
    <div className="h-padded v-padded-lg">
      <h1>Cadastrar Usuário</h1>
      <UserForm />
    </div>
  );
};

export default CreateUsers;
