import { styled } from "styled-components";

export const PageWrapper = styled.div`
  background: linear-gradient(
    90deg,
    rgba(255, 216, 90, 1) 0%,
    rgba(255, 216, 90, 1) 35%,
    rgba(241, 241, 241, 1) 35%,
    rgba(241, 241, 241, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  h1,
  h2,
  h3,
  h4 {
    text-align: center;
    color: var(--dark);
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 18px;
    }

    h2 {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 500px) {
    background: linear-gradient(
      180deg,
      rgba(255, 216, 90, 1) 0%,
      rgba(255, 216, 90, 1) 25%,
      rgba(241, 241, 241, 1) 25%,
      rgba(241, 241, 241, 1) 100%
    );

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
    }
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 88%;
  max-height: 90%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 216, 90, 1) 0%,
    rgba(255, 216, 90, 1) 32.95%,
    rgba(246, 246, 246, 1) 32.95%,
    rgba(246, 246, 246, 1) 100%
  );
  filter: drop-shadow(10px 10px 20px rgba(3, 3, 3, 0.5));

  @media only screen and (max-width: 500px) {
    display: block;
    max-width: 92%;
    max-height: 92%;
    background: linear-gradient(
      180deg,
      rgba(255, 216, 90, 1) 0%,
      rgba(255, 216, 90, 1) 22.8%,
      rgba(246, 246, 246, 1) 22.8%,
      rgba(246, 246, 246, 1) 100%
    );
  }

  @media only screen and (max-width: 300px) {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 32.95%;
  width: 100%;
  position: relative;

  @media only screen and (max-width: 500px) {
    max-width: 100%;
    width: auto;
    max-height: 22.8%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const FormWrapper = styled.form`
  width: 100%;
  padding: 6rem;

  & > div {
    margin-bottom: 2rem;

    &.m-sm {
      margin-bottom: 0.75rem;
    }
  }

  button {
    margin: 0 auto 3rem;
  }

  p {
    text-align: end;
  }

  span.email {
    color: var(--blue2);
    cursor: pointer;
  }

  a {
    font-size: 12px;
    color: var(--dark);

    &:hover {
      color: var(--lead);
      text-decoration: underline;
    }

    &.btn {
      color: var(--lighter);
      text-decoration: none;
    }
  }

  div#check-container {
    font-size: 12px;

    label {
      margin: 0 0 0 0.5rem;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 3rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 1.5rem;

    p {
      font-size: 12px;
    }

    a {
      font-size: 10px;
    }

    label {
      font-size: 14px;
    }

    input {
      font-size: 12px;
    }

    div#check-container label {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 500px) {
    width: auto;

    p {
      font-size: 14px;
    }

    a {
      font-size: 12px;
    }

    label {
      font-size: 16px;
    }

    input {
      font-size: 14px;
    }

    div#check-container label {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 400px) {
    padding: 0.75rem 1.5rem;
  }

  @media only screen and (max-width: 300px) {
    padding: 0.75rem;

    a {
      font-size: 10px;
    }

    div#check-container label {
      font-size: 10px;
    }
  }
`;
