import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as S from "./styles";

import { api } from "../../services/api";

import { useMediaLib } from "../../contexts/MediaLibContext";
import { useLoading } from "../../contexts/LoadingContext";
import { useAuth } from "../../contexts/AuthContext";

const Navbar = () => {
  const [selectedButton, setSelectedButton] = useState(null);

  const { setShowMediaLib } = useMediaLib();
  const { setLoading } = useLoading();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    setLoading(true);
    await api
      .post("/logout")
      .then(() => {
        setUser(null);
        navigate("/");
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  };

  const selectHandler = id => {
    const newSelection = selectedButton === id ? null : id;
    setSelectedButton(newSelection);
  };

  return (
    <>
      <S.NavbarWrapper className="screen-l">
        <S.NavbarContent>
          <div className="flexbox v-centered h-centered title">
            <Link to={"/"}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/logos/gta.svg`}
                alt="GTA Guaritas"
              />
            </Link>
          </div>

          <div>
            <div className="btn-container">
              <S.NavBtn
                id="btn-optionals"
                onClick={() => selectHandler(0)}
                color="var(--blue3)"
                className={selectedButton === 0 ? "selected" : undefined}
              >
                <span className="material-symbols-rounded">lists</span>
                Adicionais
                <span className="material-symbols-rounded">expand_more</span>
              </S.NavBtn>
              <S.LinksContainer
                id="links-optionals"
                color="var(--blue3)"
                className={selectedButton === 0 ? "active" : undefined}
              >
                <Link to={"/optionals"}>Lista</Link>
                <Link to={"/optionals/register"}>Cadastrar</Link>
              </S.LinksContainer>
            </div>

            <div className="btn-container">
              <S.NavBtn
                id="btn-models"
                onClick={() => selectHandler(1)}
                color="var(--green2)"
                className={selectedButton === 1 ? "selected" : undefined}
              >
                <span className="material-symbols-rounded">category</span>
                Modelos
                <span className="material-symbols-rounded">expand_more</span>
              </S.NavBtn>
              <S.LinksContainer
                id="links-models"
                color="var(--green2)"
                className={selectedButton === 1 ? "active" : undefined}
              >
                <Link to={"/model-types"}>Lista</Link>
                <Link to={"/model-types/register"}>Cadastrar</Link>
              </S.LinksContainer>
            </div>

            <div className="btn-container">
              <S.NavBtn
                id="btn-products"
                onClick={() => selectHandler(2)}
                color="var(--purple)"
                className={selectedButton === 2 ? "selected" : undefined}
              >
                <span className="material-symbols-rounded">inventory</span>
                Produtos
                <span className="material-symbols-rounded">expand_more</span>
              </S.NavBtn>
              <S.LinksContainer
                id="links-products"
                color="var(--purple)"
                className={selectedButton === 2 ? "active" : undefined}
              >
                <Link to={"/products"}>Lista</Link>
                <Link to={"/products/register"}>Cadastrar</Link>
              </S.LinksContainer>
            </div>

            <div className="btn-container">
              <S.NavBtn
                id="btn-slides"
                onClick={() => selectHandler(3)}
                color="var(--red2)"
                className={selectedButton === 3 ? "selected" : undefined}
              >
                <span className="material-symbols-rounded">
                  wallpaper_slideshow
                </span>
                Slides
                <span className="material-symbols-rounded">expand_more</span>
              </S.NavBtn>
              <S.LinksContainer
                id="links-slides"
                color="var(--red2)"
                className={selectedButton === 3 ? "active" : undefined}
              >
                <Link to={"/carousel-slides"}>Lista</Link>
                <Link to={"/carousel-slides/register"}>Cadastrar</Link>
              </S.LinksContainer>
            </div>

            <div className="btn-container">
              <S.NavBtn
                id="btn-media"
                onClick={() => selectHandler(4)}
                color="var(--orange2)"
                className={selectedButton === 4 ? "selected" : undefined}
              >
                <span className="material-symbols-rounded">video_library</span>
                Mídia
                <span className="material-symbols-rounded">expand_more</span>
              </S.NavBtn>
              <S.LinksContainer
                id="links-media"
                color="var(--orange2)"
                className={selectedButton === 4 ? "active" : undefined}
              >
                <button type="button" onClick={() => setShowMediaLib(true)}>
                  Biblioteca
                </button>
              </S.LinksContainer>
            </div>

            {user.role === "admin" && (
              <div className="btn-container">
                <S.NavBtn
                  id="btn-users"
                  onClick={() => selectHandler(5)}
                  color="var(--yellow2)"
                  className={selectedButton === 5 ? "selected" : undefined}
                >
                  <span className="material-symbols-rounded">
                    manage_accounts
                  </span>
                  Usuários
                  <span className="material-symbols-rounded">expand_more</span>
                </S.NavBtn>
                <S.LinksContainer
                  id="links-users"
                  color="var(--yellow2)"
                  className={selectedButton === 5 ? "active" : undefined}
                >
                  <Link to={"/users"}>Lista</Link>
                  <Link to={"/users/register"}>Cadastrar</Link>
                </S.LinksContainer>
              </div>
            )}
          </div>

          <S.LogoutBtn onClick={logoutHandler}>
            <span className="material-symbols-rounded">logout</span>Sair da
            conta
          </S.LogoutBtn>
        </S.NavbarContent>
      </S.NavbarWrapper>

      <S.NavbarWrapper className="screen-s">
        <S.NavbarContent>
          <div className="btn-container">
            <NavLink to={"/optionals"} className="link-mobile">
              <span className="material-symbols-rounded">lists</span>
              Adicionais
            </NavLink>
          </div>

          <div className="btn-container">
            <NavLink to={"/model-types"} className="link-mobile">
              <span className="material-symbols-rounded">category</span>
              Modelos
            </NavLink>
          </div>

          <div className="btn-container">
            <NavLink to={"/products"} className="link-mobile">
              <span className="material-symbols-rounded">inventory</span>
              Produtos
            </NavLink>
          </div>

          <div className="btn-slides">
            <NavLink to={"/carousel-slides"} className="link-mobile">
              <span className="material-symbols-rounded">
                wallpaper_slideshow
              </span>
              Slides
            </NavLink>
          </div>

          {user.role === "admin" && (
            <div className="btn-container">
              <NavLink to={"/users"} className="link-mobile">
                <span className="material-symbols-rounded">
                  manage_accounts
                </span>
                Usuários
              </NavLink>
            </div>
          )}
        </S.NavbarContent>
      </S.NavbarWrapper>
    </>
  );
};

export default Navbar;
