import React from "react";
import * as S from "../styles/guestPages";

import { api } from "../services/api";
import { fireSuccessMsg, onRequestError } from "../services/functions";
import { useLoading } from "../contexts/LoadingContext";

import { useNavigate, Link } from "react-router-dom";

const ConfirmAccount = () => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const onSuccess = response => {
    if (response.status === 200) {
      navigate("/");
      fireSuccessMsg(response.data.msg);
    } else {
      onRequestError(
        "Algo deu errado! Contate o administrador do sistema",
        response
      );
    }
  };

  const handleEmail = e => {
    e.preventDefault();
    setLoading(true);

    api
      .post("/email/verification-notification")
      .then(response => onSuccess(response))
      .catch(e => {
        if (!e.caught) {
          e.msg =
            "Houve um problema ao solicitar um novo email de confirmação!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.FormWrapper onSubmit={handleEmail}>
      <div>
        <h3 style={{ marginBottom: "0.5rem" }}>
          Você ainda não confirmou sua conta!
        </h3>
        <h4>Clique no botão abaixo para solicitar seu email de confirmação.</h4>
      </div>

      <div style={{ textAlign: "end" }}>
        <Link to={"/"}>Voltar para login</Link>
      </div>

      <button type="submit" className="btn blue lg full">
        Solicitar email de confirmação
      </button>
    </S.FormWrapper>
  );
};

export default ConfirmAccount;
