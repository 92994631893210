import React, { useEffect, useRef, useState } from "react";
import * as S from "../styles/guestPages";

import { api } from "../services/api";
import {
  onRequestError,
  validationNeutral,
  validationSuccess,
  validationError,
  checkValidations,
  fireSuccessMsg,
} from "../services/functions";
import { useLoading } from "../contexts/LoadingContext";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passConfirmVisibility, setPassConfirmVisibility] = useState(false);
  const [validated, setValidated] = useState(false);

  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const validations = useRef({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    symbol: false,
    match: false,
  });

  useEffect(() => {
    if (!password) {
      validationNeutral("validate-length", validations);
      validationNeutral("validate-lowercase", validations);
      validationNeutral("validate-uppercase", validations);
      validationNeutral("validate-number", validations);
      validationNeutral("validate-symbol", validations);
      return;
    }

    password.length >= 8
      ? validationSuccess("validate-length", validations)
      : validationError("validate-length", validations);

    /[a-z]/.test(password)
      ? validationSuccess("validate-lowercase", validations)
      : validationError("validate-lowercase", validations);

    /[A-Z]/.test(password)
      ? validationSuccess("validate-uppercase", validations)
      : validationError("validate-uppercase", validations);

    /\d/.test(password)
      ? validationSuccess("validate-number", validations)
      : validationError("validate-number", validations);

    /[!@#$%^&*+=_~;:´`?'"<>{,/|}.[\]()-]/.test(password)
      ? validationSuccess("validate-symbol", validations)
      : validationError("validate-symbol", validations);
  }, [password]);

  useEffect(() => {
    if (!password || !passwordConfirmation) {
      validationNeutral("validate-match", validations);
    } else if (password === passwordConfirmation) {
      validationSuccess("validate-match", validations);
    } else {
      validationError("validate-match", validations);
    }

    setValidated(checkValidations(validations));
  }, [password, passwordConfirmation]);

  const onSuccess = response => {
    if (response.status === 200) {
      navigate("/");
      fireSuccessMsg("Senha alterada com sucesso! Realize seu login");
    } else {
      onRequestError(
        "Algo deu errado! Contate o administrador do sistema",
        response
      );
    }
  };

  const handleReset = e => {
    e.preventDefault();
    setLoading(true);

    api
      .post("/reset-password", {
        token: token,
        email: searchParams.get("email"),
        password: password,
        password_confirmation: passwordConfirmation,
      })
      .then(response => onSuccess(response))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao redefinir a senha!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.FormWrapper onSubmit={handleReset}>
      <div>
        <h3>Alteração de senha</h3>
      </div>

      <div>
        <label htmlFor="inp_pass">Nova senha</label>
        <div className="requirements">
          <p>A senha deve ter, no mínimo:</p>
          <p id="validate-length">
            <span className="material-symbols-rounded">
              fiber_manual_record
            </span>
            8 caracteres;
          </p>
          <p id="validate-lowercase">
            <span className="material-symbols-rounded">
              fiber_manual_record
            </span>
            uma letra minúscula;
          </p>
          <p id="validate-uppercase">
            <span className="material-symbols-rounded">
              fiber_manual_record
            </span>
            uma letra maiúscula;
          </p>
          <p id="validate-number">
            <span className="material-symbols-rounded">
              fiber_manual_record
            </span>
            um número;
          </p>
          <p id="validate-symbol">
            <span className="material-symbols-rounded">
              fiber_manual_record
            </span>
            um caractere especial
          </p>
        </div>
        <div style={{ position: "relative" }}>
          <input
            id="inp_pass"
            type={passwordVisibility ? "text" : "password"}
            placeholder="Cadastre uma nova senha"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <span
            className="material-symbols-rounded eye-btn"
            onClick={() => setPasswordVisibility(!passwordVisibility)}
          >
            {passwordVisibility ? "visibility_off" : "visibility"}
          </span>
        </div>
      </div>

      <div>
        <label htmlFor="inp_pass_confirm">Confirmação de senha</label>
        <div className="requirements">
          <p id="validate-match">
            <span className="material-symbols-rounded">
              fiber_manual_record
            </span>
            As senhas devem ser iguais
          </p>
        </div>
        <div style={{ position: "relative" }}>
          <input
            id="inp_pass_confirm"
            type={passConfirmVisibility ? "text" : "password"}
            placeholder="Confirme sua senha"
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
            required
          />
          <span
            className="material-symbols-rounded eye-btn"
            onClick={() => setPassConfirmVisibility(!passConfirmVisibility)}
          >
            {passConfirmVisibility ? "visibility_off" : "visibility"}
          </span>
        </div>
      </div>

      <button type="submit" className="btn blue lg full" disabled={!validated}>
        Alterar senha
      </button>
    </S.FormWrapper>
  );
};

export default PasswordReset;
