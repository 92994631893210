import OptionalForm from "../../components/OptionalForm";

const CreateOptionals = () => {
  return (
    <div className="h-padded v-padded-lg">
      <h1>Cadastrar Adicional</h1>
      <OptionalForm />
    </div>
  );
};

export default CreateOptionals;
