import { Link } from "react-router-dom";
import * as S from "./styles";

import StyledCheckbox from "../StyledCheckbox";
import { useEffect } from "react";

const ItemCard = ({
  index,
  item,
  routeName,
  deleteHandler,
  selectionHandler,
  selectionState,
  highlightHandler,
  warnings,
}) => {
  useEffect(() => {
    console.log(item);
  }, [item]);

  const addToSelection = () => {
    const newSelected = [...selectionState, item];
    selectionHandler(newSelected);
  };

  const removeFromSelection = () => {
    const selectedCopy = [...selectionState];
    const index = selectedCopy.indexOf(item);
    selectedCopy.splice(index, 1);
    selectionHandler(selectedCopy);

    document.getElementById("inp_select_all").checked = false;
  };

  const onSelectChange = e => {
    if (e.target.checked) {
      addToSelection();
    } else {
      removeFromSelection();
    }
  };

  const handleClick = e => {
    if (e.target.tagName === "DIV") {
      const el = document.getElementById(`inp_item_${item.id}`);
      el.click();
    }
  };

  return (
    <S.ItemCardWrapper onClick={handleClick} $highlight={item.highlight}>
      <div className="flexbox v-centered" style={{ marginRight: "1rem" }}>
        <StyledCheckbox id={`inp_item_${item.id}`} handler={onSelectChange} />

        <p style={{ marginRight: "0.5rem" }}>{`${index + 1}-`}</p>

        <div>
          <h4>{item.name}</h4>

          {item.sale_type && (
            <p>{`${item.sale_type} / ${item.width} x ${item.length}`}</p>
          )}

          {warnings?.map((warn, i) => {
            if (warn.condition) {
              return (
                <p key={i} className="warning">
                  {warn.label}
                </p>
              );
            }

            return null;
          })}
        </div>
      </div>

      <div className="flexbox card-controls">
        <Link to={`/${routeName}/edit/${item.id}`} className="btn lead md">
          <span className="material-symbols-rounded">edit</span>
          <span className="btn-text">Editar</span>
        </Link>
        <button onClick={() => deleteHandler(item)} className="btn red md">
          <span className="material-symbols-rounded">delete</span>
          <span className="btn-text">Excluir</span>
        </button>

        {highlightHandler && (
          <button
            onClick={() => highlightHandler(item)}
            className={`btn gold md btn-highlight${
              item.highlight ? " active" : ""
            }`}
          >
            <span className="material-symbols-rounded">star</span>
          </button>
        )}
      </div>
    </S.ItemCardWrapper>
  );
};

export default ItemCard;
