import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

import FormButtons from "../FormButtons";
import { api } from "../../services/api";
import { fireSuccessMsg, onRequestError } from "../../services/functions";
import { useLoading } from "../../contexts/LoadingContext";

const OptionalForm = ({ optional }) => {
  const [name, setName] = useState("");

  const navigate = useNavigate();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (optional) {
      setName(optional.name);
    }
  }, [optional]);

  const backToList = () => {
    navigate("/optionals");
  };

  const onSuccess = (msg, isEdit) => {
    const cb = isEdit ? backToList : null;

    fireSuccessMsg(msg, cb);
    setName("");
  };

  const createNewOptional = async () => {
    await api
      .post("/api/optionals", { name: name })
      .then(response => onSuccess(response.data.msg))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao cadastrar o adicional!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editOptional = async () => {
    await api
      .patch(`/api/optionals/${optional.id}`, { name: name })
      .then(response => onSuccess(response.data.msg, true))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao editar o adicional!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitHandler = e => {
    e.preventDefault();
    setLoading(true);
    if (optional) {
      editOptional();
    } else {
      createNewOptional();
    }
  };

  return (
    <S.OptionalFormWrapper onSubmit={submitHandler}>
      <label htmlFor="inp_name">Nome</label>
      <input
        id="inp_name"
        type="text"
        placeholder="Nome do adicional"
        maxLength={60}
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />
      <FormButtons cancelCallback={backToList} />
    </S.OptionalFormWrapper>
  );
};

export default OptionalForm;
