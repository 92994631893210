import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

import FormButtons from "../FormButtons";
import CheckboxCard from "../CheckboxCard";
import ToggleButton from "../ToggleButton";

import { api } from "../../services/api";
import { fireSuccessMsg, onRequestError } from "../../services/functions";

import { useMediaLib } from "../../contexts/MediaLibContext";
import { useLoading } from "../../contexts/LoadingContext";

import CurrencyInput from "react-currency-input-field";

const ProductForm = ({ product, productHandler, modelTypes, optionals }) => {
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [buildDescription, setBuildDescription] = useState("");
  const [inclusionsDescription, setInclusionsDescription] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [fullPrice, setFullPrice] = useState("");
  const [parsedFullPrice, setParsedFullPrice] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [parsedCurrentPrice, setParsedCurrentPrice] = useState("");
  const [saleType, setSaleType] = useState("Venda");
  const [highlight, setHighlight] = useState(0);
  const [productModelTypeIDs, setProductModelTypeIDs] = useState([]);
  const [productOptionalIDs, setProductOptionalIDs] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [levelMedia, setLevelMedia] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [openFolderId, setOpenFolderId] = useState(null);

  const navigate = useNavigate();
  const { showMediaLib, setShowMediaLib } = useMediaLib();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (showMediaLib) return;

    const getApiResources = async () => {
      const apiCalls = [
        api.get(`/api/product-media/children/${openFolderId}`),
        api.get(`/api/media-folders`),
      ];

      if (product) {
        apiCalls.push(api.get(`/api/products/${product.id}`));
      }

      await Promise.all(apiCalls)
        .then(responses => {
          setLevelMedia(responses[0].data);
          setAllFolders(responses[1].data);
          if (responses[2]) {
            productHandler(responses[2].data);
          }
        })
        .catch(e => {
          if (!e.caught) {
            e.msg = "Houve um problema ao listar os arquivos de mídia!";
          }
          onRequestError(e.msg, e);
        })
        .finally(() => setLoading(false));
    };

    setLoading(true);
    getApiResources();

    // eslint-disable-next-line
  }, [showMediaLib]);

  useEffect(() => {
    const getLevelMedia = async () => {
      await api
        .get(`/api/product-media/children/${openFolderId}`)
        .then(response => setLevelMedia(response.data))
        .catch(e => {
          if (!e.caught) {
            e.msg = "Houve um problema ao listar os arquivos da pasta!";
          }
          onRequestError(e.msg, e);
        })
        .finally(() => setLoading(false));
    };

    setLoading(true);
    getLevelMedia();

    // eslint-disable-next-line
  }, [openFolderId]);

  useEffect(() => {
    if (product) {
      // console.log("TESTE product", product);

      setName(product.name);
      setShortDescription(product.short_description);
      setFullDescription(product.full_description);
      setBuildDescription(product.build_description);
      setInclusionsDescription(product.inclusions_description);
      setHeight(product.height);
      setWidth(product.width);
      setLength(product.length);
      setFullPrice(product.full_price);
      setCurrentPrice(product.current_price);
      setSaleType(product.sale_type);
      setHighlight(product.highlight ? 1 : 0);
      setProductModelTypeIDs(product.model_types.map(mod => mod.id));
      setProductOptionalIDs(product.optionals.map(opt => opt.id));
      setSelectedMedia(product.media);
    }
  }, [product]);

  useEffect(() => {
    if (!fullPrice) {
      setParsedFullPrice(null);
      return;
    }

    const parsed = parseFloat(fullPrice.replace(",", "."));
    setParsedFullPrice(parsed);
  }, [fullPrice]);

  useEffect(() => {
    if (!currentPrice) {
      setParsedCurrentPrice(null);
      return;
    }

    const parsed = parseFloat(currentPrice.replace(",", "."));
    setParsedCurrentPrice(parsed);
  }, [currentPrice]);

  const backToList = () => {
    navigate("/products");
  };

  const onSuccess = (msg, isEdit) => {
    const cb = isEdit ? backToList : null;

    fireSuccessMsg(msg, cb);
    setName("");
    setShortDescription("");
    setFullDescription("");
    setBuildDescription("");
    setInclusionsDescription("");
    setHeight("");
    setWidth("");
    setLength("");
    setFullPrice("");
    setCurrentPrice("");
    setSaleType("Venda");
    setHighlight(0);
    setProductModelTypeIDs([]);
    setProductOptionalIDs([]);
    setSelectedMedia([]);
  };

  const createNewProduct = async () => {
    await api
      .post("/api/products", {
        name: name,
        short_description: shortDescription,
        full_description: fullDescription,
        build_description: buildDescription,
        inclusions_description: inclusionsDescription,
        height: height,
        width: width,
        length: length,
        full_price: parsedFullPrice,
        current_price: parsedCurrentPrice,
        sale_type: saleType,
        highlight: highlight,
        model_type_ids: productModelTypeIDs,
        optional_ids: productOptionalIDs,
        media_ids: selectedMedia.map(media => media.id),
      })
      .then(response => onSuccess(response.data.msg))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao cadastrar o produto!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editProduct = async () => {
    await api
      .patch(`/api/products/${product.id}`, {
        name: name,
        short_description: shortDescription,
        full_description: fullDescription,
        build_description: buildDescription,
        inclusions_description: inclusionsDescription,
        height: height,
        width: width,
        length: length,
        full_price: parsedFullPrice,
        current_price: parsedCurrentPrice,
        sale_type: saleType,
        highlight: highlight,
        model_type_ids: productModelTypeIDs,
        optional_ids: productOptionalIDs,
        media_ids: selectedMedia.map(media => media.id),
      })
      .then(response => onSuccess(response.data.msg, true))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Algo deu errado ao editar o produto!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitHandler = e => {
    e.preventDefault();
    setLoading(true);

    if (product) {
      editProduct();
    } else {
      createNewProduct();
    }
  };

  const folderHasUnusedContent = data => {
    const index = data.media?.findIndex(media => media.products.length === 0);
    return index !== undefined && index !== -1;
  };

  const renderFolderLink = data => {
    let hasUnusedContent = folderHasUnusedContent(data);
    let btnClass = data.id === openFolderId ? "is-active" : undefined;

    const handler = () => {
      setOpenFolderId(data.id);
    };

    return (
      <button type="button" onClick={handler} className={btnClass}>
        {data.name}
        {hasUnusedContent && (
          <span style={{ color: "var(--red)", fontWeight: "bolder" }}> !</span>
        )}
      </button>
    );
  };

  const renderFolderLinkLevel = data => {
    const hasSubs = data.subfolders.length > 0;

    return (
      <li key={data.id}>
        {renderFolderLink(data)}
        {hasSubs && (
          <ul>{data.subfolders.map(sub => renderFolderLinkLevel(sub))}</ul>
        )}
      </li>
    );
  };

  const renderNavTree = () => {
    return (
      <ul>
        <li>{renderFolderLink({ id: null, name: "/" })}</li>
        <ul>{allFolders.map(folder => renderFolderLinkLevel(folder))}</ul>
      </ul>
    );
  };

  return (
    <S.ProductFormWrapper onSubmit={submitHandler}>
      <div>
        <ToggleButton
          id="inp_highlight"
          label="Destacar na página principal"
          state={highlight}
          handler={e => setHighlight(e.target.checked ? 1 : 0)}
        />
      </div>

      <div>
        <label htmlFor="inp_name">Nome</label>
        <input
          id="inp_name"
          type="text"
          placeholder="Nome do produto"
          maxLength={128}
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_short_description">Descrição curta</label>
        <input
          id="inp_short_description"
          type="text"
          placeholder="Descrição curta do produto"
          value={shortDescription}
          onChange={e => setShortDescription(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_full_description">Descrição completa</label>
        <textarea
          id="inp_full_description"
          placeholder="Descrição completa do produto"
          value={fullDescription}
          onChange={e => setFullDescription(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_build_description">Construção</label>
        <textarea
          id="inp_build_description"
          placeholder="Detalhes da construção do produto"
          value={buildDescription}
          onChange={e => setBuildDescription(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="inp_inclusions_description">Inclusos</label>
        <textarea
          id="inp_inclusions_description"
          placeholder="Inclusos no produto"
          value={inclusionsDescription}
          onChange={e => setInclusionsDescription(e.target.value)}
          required
        />
      </div>

      <div className="flexbox">
        <div>
          <label htmlFor="inp_height">Altura (m)</label>
          <input
            id="inp_height"
            type="number"
            min="0"
            max="99"
            step="0.01"
            placeholder="0,00"
            value={height}
            onChange={e => setHeight(e.target.value)}
            onBlur={e => setHeight(parseFloat(e.target.value).toFixed(2))}
            required
          />
        </div>

        <div>
          <label htmlFor="inp_width">Largura (m)</label>
          <input
            id="inp_width"
            type="number"
            min="0"
            max="99"
            step="0.01"
            placeholder="0,00"
            value={width}
            onChange={e => setWidth(e.target.value)}
            onBlur={e => setWidth(parseFloat(e.target.value).toFixed(2))}
            required
          />
        </div>

        <div>
          <label htmlFor="inp_length">Profundidade (m)</label>
          <input
            id="inp_length"
            type="number"
            min="0"
            max="99"
            step="0.01"
            placeholder="0,00"
            value={length}
            onChange={e => setLength(e.target.value)}
            onBlur={e => setLength(parseFloat(e.target.value).toFixed(2))}
            required
          />
        </div>
      </div>

      <div className="flexbox h-spaced-between">
        <div>
          <label htmlFor="inp_full_price">Preço no crédito</label>
          <CurrencyInput
            id="inp_full_price"
            placeholder="R$ 0,00"
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            allowNegativeValue={false}
            decimalScale={2}
            fixedDecimalLength={2}
            maxLength={7}
            value={fullPrice}
            onValueChange={value => setFullPrice(value)}
          />
        </div>

        <div>
          <label htmlFor="inp_current_price">Preço à vista</label>
          <CurrencyInput
            id="inp_current_price"
            placeholder="R$ 0,00"
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            allowNegativeValue={false}
            decimalScale={2}
            fixedDecimalLength={2}
            maxLength={7}
            value={currentPrice}
            onValueChange={value => setCurrentPrice(value)}
            required
          />
        </div>
      </div>

      <div>
        <label htmlFor="inp_sale_type">Tipo de venda</label>
        <select
          id="inp_sale_type"
          name="sale_type"
          value={saleType}
          onChange={e => setSaleType(e.target.value)}
          required
        >
          <option value={"Venda"}>Venda</option>
          <option value={"Locação"}>Locação</option>
        </select>
      </div>

      {modelTypes?.length > 0 && (
        <div>
          <p>Modelos do produto</p>
          <S.ProductCheckboxWrapper>
            {modelTypes.map((mt, i) => (
              <CheckboxCard
                key={i}
                data={mt}
                idArrayState={productModelTypeIDs}
                idArrayHandler={setProductModelTypeIDs}
              />
            ))}
          </S.ProductCheckboxWrapper>
        </div>
      )}

      {optionals?.length > 0 && (
        <div>
          <p>Adicionais do produto</p>
          <S.ProductCheckboxWrapper>
            {optionals.map((opt, i) => (
              <CheckboxCard
                key={i}
                data={opt}
                idArrayState={productOptionalIDs}
                idArrayHandler={setProductOptionalIDs}
              />
            ))}
          </S.ProductCheckboxWrapper>
        </div>
      )}

      <div>
        <p>Imagens do produto</p>

        <div className="flexbox v-centered file-select">
          <S.ProductCheckboxWrapper className="single-col sm stretched">
            <h3>Pastas</h3>
            {renderNavTree()}
          </S.ProductCheckboxWrapper>

          <S.ProductCheckboxWrapper className="uneven stretched">
            <h3>Conteúdos da pasta</h3>
            <div className="container">
              {levelMedia.length > 0 ? (
                levelMedia.map((media, i) => (
                  <CheckboxCard
                    key={i}
                    data={media}
                    contentPath={`${api.getUri()}/storage/${media.path}`}
                    dataArrayState={selectedMedia}
                    dataArrayHandler={setSelectedMedia}
                  />
                ))
              ) : (
                <p>Nenhuma mídia foi encontrada na pasta selecionada</p>
              )}
            </div>
          </S.ProductCheckboxWrapper>
        </div>

        <S.ProductCheckboxWrapper className="uneven margin-l">
          <h3>Conteúdos selecionados</h3>
          <div className="container">
            {selectedMedia.length > 0 ? (
              selectedMedia.map((media, i) => (
                <CheckboxCard
                  key={i}
                  data={media}
                  contentPath={`${api.getUri()}/storage/${media.path}`}
                  dataArrayState={selectedMedia}
                  dataArrayHandler={setSelectedMedia}
                />
              ))
            ) : (
              <p>Nenhuma mídia foi encontrada relacionada a este produto</p>
            )}
          </div>
        </S.ProductCheckboxWrapper>

        <button
          type="button"
          className="btn blue"
          onClick={() => setShowMediaLib(true)}
          style={{ margin: "1rem auto" }}
        >
          <span
            className="material-symbols-rounded"
            style={{ marginRight: "0.5rem" }}
          >
            add
          </span>
          <span style={{ fontSize: 16 }}>Abrir biblioteca de mídia</span>
        </button>
      </div>

      <FormButtons cancelCallback={backToList} />
    </S.ProductFormWrapper>
  );
};

export default ProductForm;
